<template>
    <div class="relative" ref="dropdownRef">
        <div class="flex">
      <ToastAlert
        :is-active="toastAlert"
        :type="typeMsg"
        :message="msg"
      />
    </div>
      <!-- Botón principal -->
      <button
        @click="toggleDropdown"
        class="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md text-gray-600 hover:text-gray-800 border border-gray-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="w-6 h-6"
        >
          <circle cx="18" cy="5" r="3" />
          <circle cx="6" cy="12" r="3" />
          <circle cx="18" cy="19" r="3" />
          <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
          <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
        </svg>
      </button>
  
      <!-- Menú desplegable con las opciones dentro de un cuadro -->
      <div
        v-if="isOpen"
        class="absolute bottom-12 left-0 w-auto bg-white shadow-md p-2 rounded-md flex justify-center items-center space-x-4 z-10"
      >
        <!-- Opciones dentro de un solo cuadro -->
        <!-- Botón para compartir por WhatsApp -->
        <button
          @click="shareViaWhatsApp"
          class="flex items-center justify-center w-10 h-10 rounded-full text-green-500 hover:bg-gray-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-6 h-6"
          >
            <path
              d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
            />
          </svg>
        </button>
  
        <!-- Botón para compartir en Facebook -->
        <button
          @click="shareViaFacebook"
          class="flex items-center justify-center w-10 h-10 rounded-full text-blue-600 hover:bg-gray-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-6 h-6"
          >
            <path
              d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
            />
          </svg>
        </button>
  
        <!-- Botón para compartir en X (anteriormente Twitter) -->
        <button
        @click="shareViaTwitter"
        class="flex items-center justify-center w-10 h-10 rounded-full text-black hover:bg-gray-100"
        >
        <!-- Logotipo de X (SVG proporcionado) -->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 140 140">
            <path d="M75.916 54.2L122.542 0h-11.05L71.008 47.06L38.672 0H1.376l48.898 71.164L1.376 128h11.05L55.18 78.303L89.328 128h37.296L75.913 54.2ZM60.782 71.79l-4.955-7.086l-39.42-56.386h16.972L65.19 53.824l4.954 7.086l41.353 59.15h-16.97L60.782 71.793Z"/>
        </svg>
        </button>

        <!-- Botón para copiar al portapapeles -->
        <button
          @click="copyToClipboard"
          class="flex items-center justify-center w-10 h-10 rounded-full text-gray-500 hover:bg-gray-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-6 h-6"
          >
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
          </svg>
        </button>
      </div>
    </div>
  </template>
  
  
  <script setup>
  import ToastAlert from "@/commond/ToastAlert.vue";
  import { ref, onMounted, onUnmounted, computed } from 'vue';

  import axios from "axios";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  let router = useRouter();
  const store = useStore();
  const session = computed(() => store.getters.getUserSession);
  let toastAlert = ref(false);
    let typeMsg = ref("");
    let msg = ref("");
  const props = defineProps({
    propertyUrl: {
      type: String,
      required: true,
    },
    propertyName: {
      type: String,
      required: true,
    },
    propertyId: {  // Asegúrate de que esta propiedad esté definida
      type: [String, Number],
      required: true
    }
  });
  
  const isOpen = ref(false);
  const dropdownRef = ref(null);
  
  const toggleDropdown = () => {
    isOpen.value = !isOpen.value;
    logUserAction(props.propertyId, 7, 'Usuario hizó clic en el botón de compartir propiedad');
  };
  
  const handleClickOutside = (event) => {
    if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
      isOpen.value = false;
    }
  };

  async function logUserAction(propertyId, typeAction, detail) {
    try {
      let url_cnn = '';
      url_cnn = `${process.env.VUE_APP_API_BASE_URL}/event/add_log`; // Cambia la ruta según tu necesidad

      let param_analytics = {
        "activity_id": typeAction,
        "description": detail,
        "property_id": propertyId,
        "user_id": session.value.id // Aquí podrías incluir el ID del usuario si es necesario
      };
      console.log(param_analytics)
      let options = {
        headers: {
          "Content-Type": "application/json", // Es importante para la solicitud de JSON
          Authorization: `Bearer ${session.value.token_api}`, // Reemplaza con tu token real
        },
      };

      const response = await axios.post(url_cnn, param_analytics, options);

      if (response.status === 200) {
        console.log('Log enviado correctamente:', response.data);
        // Aquí puedes manejar lo que se hace con la respuesta si es necesario
      }

    } catch (error) {
      console.error('Error al enviar el log:', error);

      if (error.response && error.response.status === 401) {
        store.dispatch("clearUserSession");
        router.push({ name: "login" });
      }
    }
  }
  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });
  
  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
  });
  
  const shareViaWhatsApp = () => {
    const text = `Mira esta propiedad: ${props.propertyName}\n${props.propertyUrl}`;
    window.open(`https://wa.me/?text=${encodeURIComponent(text)}`, '_blank');
    isOpen.value = false;
    // Log de la acción
    logUserAction(props.propertyId, 8, 'Usuario compartió la propiedad vía WhatsApp');
  };
  
  const shareViaFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        props.propertyUrl
      )}`,
      '_blank'
    );
    isOpen.value = false;
    logUserAction(props.propertyId, 9, 'Usuario compartió la propiedad vía Facebook');
  };
  
  const shareViaTwitter = () => {
    const text = `Mira esta propiedad: ${props.propertyName}`;
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text
      )}&url=${encodeURIComponent(props.propertyUrl)}`,
      '_blank'
    );
    isOpen.value = false;

    logUserAction(props.propertyId, 10, 'Usuario compartió la propiedad vía Twitter');
  };
  
  const copyToClipboard = async () => {
    try {
        await navigator.clipboard.writeText(props.propertyUrl);
        isOpen.value = false;
        showToast('success', '¡Enlace copiado al portapapeles!');
        // Log de la acción
        logUserAction(props.propertyId, 44, 'Usuario copió el enlace al portapapeles');
    } catch (err) {
        console.error('Failed to copy:', err);
        showToast('Error', 'No se pudo copiar el enlace.');
    }
    };

  async function closeToast() {
    toastAlert.value = false;
    typeMsg.value = "";
    msg.value = "";
    }

    async function showToast(type, message){
    toastAlert.value = true;
    typeMsg.value = type;
    msg.value = message;

    setTimeout(() => {
        closeToast()
    }, 3000);
    }
  </script>
  