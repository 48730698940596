
<template>
    <div class="w-full flex justify-center">
      <!-- inicio de contenido -->
      <div
        class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-5 sm:mt-10"      
      >
        <template v-if="hasSlug">
          <fwb-tabs
            v-model="activeTab"
            class="p-5"
          >
            <fwb-tab
              name="first"
              title="Datos generales"
            >
              <FormCompaComponent
                :property-id="id"
                @trigger-update-property="updateProperty"
              />
            </fwb-tab>
            <fwb-tab
              name="ubication"
              title="Ubicación"
              :disabled="!active_tab"
            >
              <FormLocationComponent
                :resp="data_company"
                :property-id="id"
                Type="company" 
              />
            </fwb-tab>
          </fwb-tabs>
        </template>
        <template v-else>
          <div class="p-5">
            <FormCompaComponent
              :property-id="id"
              @trigger-update-property="updateProperty"
            />
          </div>
        </template>
      </div>
    </div>
  </template>
  <script setup>
  
  import {
    FwbTab,
    FwbTabs,
  } from "flowbite-vue";
  import { ref, onMounted, watch, computed } from "vue";
  import { useRoute } from "vue-router";
  import FormLocationComponent from "./FormLocationComponent.vue";
  import { useStore } from "vuex";
  import axios from "axios";
    //import FormCompanyComponent from "./FormCompanyComponent.vue";
  import FormCompaComponent from "./FormCompaComponent.vue";
  const store = useStore();
  let session = computed(() => store.getters.getUserSession);
  // Acceder al parámetro 'id' de la URL directamente
  const route = useRoute();
  let id = session.value.id;
  let active_tab = ref(true);
  const activeTab = ref("first");
  let step = ref(3);
  let data_company = ref({});
  const hasSlug = ref(false); 
  
  onMounted(async () => {
    console.log('[ID PROPIEDAD]',id)
    if (id != undefined) {
      active_tab.value = true;
    }
  });
  
  async function getInfoCompany(){
  
    let options = {
        headers: {
        Authorization: `Bearer ${session.value.token_api}`,
        },
    };

    let link_api = `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/company`;
    const response = await axios.get(
        link_api,
        options
        );
    try {
        if (response.status == 401) {
        store.dispatch("clearUserSession");
        }

        if (response.status == 200) {
            console.log(response)
            data_company.value = response;
            
            step.value = 2;
        
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
    }
  
  async function updateProperty(property_id){
    console.log('actualiza estado de tabs', property_id)
    active_tab.value = true;
  }
  onMounted(async () => {
      try {
        await getInfoCompany();
        // Modificamos la ruta para acceder correctamente al slug
        hasSlug.value = data_company.value?.data?.resp?.info?.slug ? true : false;
        step.value = session.value.step;
      } catch (error) {
        console.error("Error al intentar traer los datos", error);
      }
  });
  watch(activeTab, async (newTab) => {
    if (newTab === 'first') {
      await getInfoCompany(); // Al seleccionar "Datos generales", cargamos los datos de la compañía
    }
  });
  watch(
    () => route.params.id,
    (newId) => {
      console.log('[-----]',newId)
        id =  newId
    }
  );
  
  </script>