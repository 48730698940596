<template>
  <div>
    <!-- Loader  -->
    <div
      v-if="loader"
      class="fixed inset-0 flex items-center justify-center bg-black z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      >
    </div>
    <!-- Loader  -->
    <!-- toaster  -->
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />
    <!-- toaster  -->

    <div
      v-if="isValidToken && !loader"
      class="flex min-h-screen bg-dark_bg-100 text-white h-full"
    >
      <div class="hidden w-1/2 sm:flex items-center justify-center">
        <div>
          <img
            src="@/assets/imgs/door.jpg"
            alt="Tuin hora de hacer magia"
            class="w-full h-full max-h-screen rounded-lg shadow-lg"
          >
        </div>
      </div>

      <div
        class="w-full sm:w-1/2 flex flex-col items-center justify-center bg-black min-h-screen"
      >
        <img
          src="@/assets/imgs/LogoTuin.svg"
          alt="Logo"
          class="object-contain"
        >
        <h3 class="mb-6">
          Asesores
        </h3>
        <!-- card -->
        <div class="w-3/4 max-w-md bg-dark_bg-100 p-5 rounded-sm">
          <h2 class="text-2xl font-semibold mb-6">
            Restaurar contraseña
          </h2>
          <p class="text-white">
            Ingresa los siguientes campos para crear tu nueva contraseña. La
            contraseña debe tener al menos 8 caracteres,
          </p>
          <div class="mb-4">
            <fwb-input
              v-model="password"
              label="Contraseña"
              type="password"
              required=""
              label-class="text-white"
              class="custom-label"
              placeholder="Introduce tu contraseña"
            />
          </div>
          <div class="mb-4">
            <fwb-input
              v-model="c_password"
              label="Confirmar contraseña"
              type="password"
              required=""
              label-class="text-white"
              class="custom-label"
              placeholder="Confirma tu contraseña"
            />
          </div>

          <button
            class="w-full py-2 bg-tuin-100 hover:bg-tuin-100 rounded-md text-white font-semibold"
            @click="save()"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="!isValidToken && !loader"
      class="flex flex-col items-center justify-center min-h-screen p-6"
    >
      <div class="border-2 border-tuin_base-0 rounded-lg p-8 max-w-md w-full">
        <div class="flex justify-center mb-6">
          <img
            src="@/assets/imgs/LogoTuin.svg"
            alt=""
            class="w-40 h-40"
          >
        </div>
        <h1 class="text-2xl font-bold text-white mb-4 text-center">
          Lo sentimos este correo de restaurar contraseña ha caducado
        </h1>
        <div class="flex justify-center w-full mb-5 mt-4">
          <p class="text-white text-xl">
            Por favor vuelve a realizar los pasos de olvide mi contraseña .
          </p>
        </div>
        
        <div class="flex justify-center">
          <a
            href="/forgot-password"
            class="bg-tuin_base-0 text-white py-2 px-4 rounded-lg hover:bg-white hover:text-tuin_base-0 transition-colors"
            :disabled="!email"
          >
            Restauración de contraseña
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { FwbInput } from "flowbite-vue";
import axios from "axios";
import ToastAlert from "@/commond/ToastAlert.vue";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
let router = useRouter();
import { useStore } from "vuex";
let store = useStore();

console.log('token_temp')
let loader = ref(false);
let isValidToken = ref(false);
let password = ref('')
let c_password = ref('')

let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

const tokenTemp = route.params.token_temp;

async function validateToken(){
    try {
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let post_params = {
      token: tokenTemp,
    };

    let link = `${process.env.VUE_APP_API_BASE_URL}/validate_token`;

    const response = await axios.post(link, post_params, options);

    if (response.status == 200) {
      console.log(response.data.status);
      isValidToken.value = true;
      loader.value = false;
    }
  } catch (error) {
    if (error.response.status == 422) {
      loader.value = false;
      console.error("Error fetching data:", error.response.data.status);
      isValidToken.value = error.response.data.status;
    }
  }
}


async function save(){
    if (
        password.value.length == 0 ||
        c_password.value.length == 0
    ) {
        showToast("danger", "Debes ingresar todos los campos");
        return;
    }

    if (password.value.length < 8) {
        showToast("danger", "La contraseña debe tener al menos 8 caracteres");
        return;
    }

    if (password.value != c_password.value) {
        showToast("danger", "La contraseña y confirmar contraseña no coinciden ");
        return;
    }

    loader.value = true;
    let post_form = {
        password: password.value,
        token: tokenTemp
    };

    let options = {
        headers: {

        },
    };

    let url_link = `${process.env.VUE_APP_API_BASE_URL}/update_restore_password`;
    try {
        const response = await axios.put(url_link, post_form, options);

        if (response.status == 401) {
            showToast("danger", "El link ha expirado , vuelve a realizar los pasos para restaurar tu contraseña");
        }

        if (response.status == 200) {
            loader.value = false;
            showToast("success", "Datos guardados correctamente");
            
            password.value = "";
            c_password.value = "";
            setTimeout(() => {
                router.push({ name: "login" });  
            }, 3000);
            
        }
    } catch (error) {
        if (error.response && error.response.status == 422) {
        console.log(error.response);
        showToast("danger", error.response.data.message);
        }
    }

}

async function showToast(type, message) {
  toastAlert.value = true;
  typeMsg.value = type;
  msg.value = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

onMounted(async () => {
  await store.dispatch("clearUserSession"); // Limpia la sesión
  loader.value = true;
  console.log('--',tokenTemp)
  if(!tokenTemp){
    loader.value        = false;
    isValidToken.value  = false;
    
  }else{
    await validateToken();
  }
  
});

</script>
  