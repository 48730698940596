<template>
  <div class="animate-fade-in">
    <div class="flex flex-col mt-2 mb-2">
      <p> Si la dirección que esta dentro del campo no aparece puedes ingresar de forma manual para hacercar el pin a la ubicación exacta de tu inmueble</p>
    </div>
    <div class="flex flex-col mt-4 mb-4">
      <input
        ref="autocompleteInput"        
        type="text"
        placeholderƒ="Ingresa una dirección"
        class="w-full"
      >
    </div>

    <div 
      id="map" 
      class="map-container" 
    />
  </div>
</template>
  
<script setup>
import { Loader } from "@googlemaps/js-api-loader";
import { onMounted, ref, toRef, watch } from "vue";

let map = ref(null);
let marker = ref(null);

const emit = defineEmits(['update-location']);

const autocomplete = ref(null);
const autocompleteInput = ref('');


let props = defineProps({
  address: {
    type: String,
    required: true,
  },
  export_lat:{
    type: String,
    required: false,
  },
  export_ln:{
    type: String,
    required: false,
  }
});

let location_param = toRef(props, 'address');
let export_lat = toRef(props, 'export_lat');
let export_ln = toRef(props, 'export_ln');

let loader;
let mapOptions = {
  center: {
    lat: 19.4270245,
    lng: -99.1676647,
  },
  zoom: 20,
}; 

// Función para inicializar el mapa
async function initMap() {
  try {
    const google = await loader.load(); // Asegúrate de que la biblioteca se cargó correctamente
    map.value = new google.maps.Map(document.getElementById("map"), mapOptions);

    // Crear un marcador draggable
    marker.value = new google.maps.Marker({
      position: mapOptions.center,
      map: map.value,
      draggable: true, // Hacer el marcador draggable
      icon: {
        url: "https://tuin.ai/imgs/PinTuin.svg", // Ruta del ícono personalizado
        scaledSize: new google.maps.Size(50, 50), // Escala del ícono (ajusta según sea necesario)
        origin: new google.maps.Point(0, 0), // Punto de origen del ícono
        anchor: new google.maps.Point(25, 50), // Punto de anclaje (en la base del ícono)
      },
    });

    // Escuchar el evento "dragend"
    marker.value.addListener("dragend", (event) => {
      const position = event.latLng;
      console.log(
        "Nueva posición del marcador:",
        position.lat(),
        position.lng()
      );

      const lat_export = position.lat();
      const lng_export = position.lng();

      // Emitir la latitud y longitud al componente padre
      emit('update-location', { lat_export, lng_export });
      // Actualiza el centro del mapa con la nueva posición
      map.value.setCenter(position);
    });
  } catch (error) {
    console.error("Error al cargar Google Maps:", error);
  }
}

async function initAutocomplete() {
  loader
    .load()
    .then(async (google) => {
      const geocoder = new google.maps.Geocoder();
      let location = null;
      location = await new Promise((resolve, reject) => {
        geocoder.geocode({ address: location_param.value }, (results, status) => {
          if (status === "OK" && results[0]) {
            //console.log('RESULTADOS ****',results[0])
            //resolve(results[0].geometry.location);
            resolve(results[0]);
          } else {
            reject(`Geocode error: ${status}`);
          }
        });
      });

      //autocompleteInput.value.value = location.formatted_address;

      if(autocompleteInput.value){
        autocompleteInput.value.value = location.formatted_address;
      }

      if (location) {
          // Actualiza las opciones del mapa con la nueva ubicación
          let lat_export = location.geometry.location.lat();
          let lng_export = location.geometry.location.lng();

          if (export_lat.value === null || export_lat.value === "") { 
            console.log('movieron el pin');
            mapOptions.center = {
              lat: location.geometry.location.lat(), 
              lng: location.geometry.location.lng(),
            };

            lat_export = location.geometry.location.lat();
            lng_export = location.geometry.location.lng();

            // Emitir la latitud y longitud al componente padre
            emit('update-location', { lat_export, lng_export });

          } else {
            console.log('movieron el pin pero entro en else');
            mapOptions.center = {
              lat: export_lat.value,
              lng: export_ln.value
            };

            lat_export = export_lat.value;
            lng_export = export_ln.value;
          }
          

          // Vuelve a centrar y ajustar el zoom del mapa
          map.value.setCenter(mapOptions.center);
          map.value.setZoom(20); // Ajusta el zoom según lo necesario
          const beachFlagImg = document.createElement("img");

          beachFlagImg.src = "https://tuin.ai/imgs/PinTuin.svg";

          marker.value.setPosition(mapOptions.center);
      }
      // Creamos la instancia de Autocomplete y la asignamos a la variable ref
      autocomplete.value = new google.maps.places.Autocomplete(
        autocompleteInput.value
      );

      autocomplete.value.addListener("place_changed", () => {
        const place = autocomplete.value.getPlace();

        if (!place.geometry || !place.geometry.location) {
          console.error("No se encontraron detalles para: " + place.name);
          return;
        }
      });
      // Escuchar el evento de cambio de lugar
      autocomplete.value.addListener("place_changed", () => {
        const place = autocomplete.value.getPlace();

        if (place.geometry) {
          // Actualiza las opciones del mapa con la nueva ubicación
          
          mapOptions.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          const lat_export = place.geometry.location.lat();
          const lng_export = place.geometry.location.lng();

          // Emitir la latitud y longitud al componente padre
          emit('update-location', { lat_export, lng_export });

          // Vuelve a centrar y ajustar el zoom del mapa
          map.value.setCenter(mapOptions.center);
          map.value.setZoom(20); // Ajusta el zoom según lo necesario
          const beachFlagImg = document.createElement("img");

          beachFlagImg.src = "https://tuin.ai/imgs/PinTuin.svg";

          marker.value.setPosition(mapOptions.center);
        }
      });
    })
    .catch((e) => {
      console.log("Error al cargar Google Maps:", e);
    });
}

onMounted(async() => {
  loader = new Loader({
    apiKey: "AIzaSyDh_2CwOWAcS258EwjQsctRq9ypVZfHAZw", // Reemplaza con tu clave de API
    libraries: ["places"], // Importante: Añade 'places' para Autocomplete
  });

  loader.load().then(async () => {
    await initMap();
    await initAutocomplete();
  });
});

watch(
  () => [props.propertyLt, props.propertyLn], // Observa ambos valores
  async ([newLat, newLng]) => { // Desestructura los nuevos valores
    mapOptions.center.lat = newLat || ''; // Establece el valor de latitud
    mapOptions.center.lng = newLng || ''; // Establece el valor de longitud
  },
  { immediate: true }
);

</script>
  
<style scoped>
.map-container {
  width: 100%;
  height: 500px;
}

.autocomplete-input {
  width: 300px;
  margin-bottom: 10px;
}

/* Animación personalizada */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
}
</style>
  