<template>
    <div class="mt-4">
      <fwb-heading tag="h3">
        Servicios
      </fwb-heading>
  
      <div class="mt-4">
        <ul class="grid grid-cols-2 md:grid-cols-4 gap-4">
          <li
            v-for="item in props.list"
            :key="item.id"
            class="flex items-center"
          >
            <div v-html="item.catalogue_services.icon" />
            <span class="ml-2"> {{ item.catalogue_services.name }} </span>
          </li>
        </ul>
      </div>
    </div>
  </template>
  <script setup>
  import { FwbHeading } from "flowbite-vue";
  const props = defineProps({
    list: {
      type: Array,
      required: true,
    },
  });
  </script>