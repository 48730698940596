<template>
    <div v-if="shouldShow" class="fixed bottom-5 right-5 flex flex-col items-center">
      <a 
        :href="whatsappLink" 
        target="_blank" 
        class="w-[40px] h-[40px] relative bg-[#51b748] rounded-full shadow-lg flex justify-center items-center hover:scale-110 transition-transform"
        @mouseover="showText = true"
        @mouseleave="showText = false"
      >
      <div class="w-[50px] h-[50px] relative">
        <div class="w-[50px] h-[50px] absolute left-0 top-0 bg-[#51b748] rounded-full"></div>
        <div data-svg-wrapper class="absolute left-[12px] top-[12px]">
            <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.07137 23.5499C4.24354 23.5105 4.32125 23.501 4.40212 23.4757C5.58109 23.0976 6.75469 22.7298 7.92639 22.3356C8.17817 22.2531 8.37056 22.274 8.59706 22.4162C11.1591 23.9755 13.9242 24.4171 16.8435 23.7692C22.3766 22.5324 26.0743 17.1538 25.294 11.5518C24.4253 5.37451 18.3965 1.0769 12.2843 2.27925C4.54713 3.80887 0.839634 12.6097 5.18336 19.1487C5.3688 19.4324 5.3925 19.6497 5.27877 19.9634C4.87409 21.126 4.49342 22.2993 4.07137 23.5499ZM0.768555 26.7905C1.13817 25.6927 1.48314 24.6616 1.83222 23.6302C2.22773 22.4635 2.63304 21.2994 3.01087 20.1246C3.06204 19.9499 3.04151 19.6911 2.95527 19.5303C-1.33981 11.7337 2.86808 2.24671 11.5638 0.315884C18.8284 -1.29651 25.8368 3.438 27.2103 10.5409C28.5994 17.7812 23.8952 24.6379 16.659 25.9183C13.7972 26.4244 11.0416 26.0238 8.43785 24.7087C8.15416 24.5669 7.91281 24.5277 7.59153 24.6348C5.46831 25.3269 3.32741 25.991 1.19187 26.6626C1.07593 26.7033 0.955572 26.7333 0.768555 26.7905Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2975 7.15756C10.9565 6.99771 11.1953 7.39797 11.3845 7.89616C11.6445 8.56398 11.9086 9.23624 12.2157 9.88258C12.41 10.3018 12.3588 10.6547 12.0802 11.006C11.9115 11.2113 11.7513 11.4397 11.5573 11.6308C11.2711 11.8984 11.2361 12.1502 11.4506 12.5021C12.4867 14.1995 13.9238 15.4283 15.7716 16.1808C16.0695 16.3056 16.2912 16.2563 16.4874 16.0074C16.7821 15.6321 17.1025 15.2802 17.3802 14.8973C17.5943 14.6073 17.8502 14.5094 18.1655 14.6541C19.0311 15.0644 19.9049 15.4713 20.7503 15.9268C20.9063 16.0106 21.0112 16.3322 21.0039 16.5388C20.9575 17.9107 20.1968 18.6626 18.6937 19.1074C18.0385 19.3033 17.3975 19.1927 16.7439 19.0224C13.0753 18.0952 10.5584 15.7458 8.69269 12.5776C8.02076 11.4327 7.69853 10.1884 8.20841 8.9058C8.41817 8.36054 8.79694 7.84751 9.21394 7.4343C9.46382 7.18821 9.92126 7.14493 10.2871 7.01193C10.2902 7.06342 10.2918 7.10734 10.2975 7.15756Z" fill="white"/>
            </svg>
        </div>
      </div>
      </a>
      <div 
        v-show="showText" 
        class="absolute bottom-[60px] right-[-10px] bg-gray-100 text-gray-500 px-3 py-1 rounded-md shadow-md text-sm min-w-max"
      >
        ¿Necesitas ayuda?
      </div>
    </div>
  </template>   
  <script>
  export default {
    data() {
      return {
        showText: false,
        phoneNumber: "525564931228", 
        excludedRoutes: ["/customers", "/login", "/bolsa-inmobiliaria"],
      };
    },
    computed: {
      whatsappLink() {
        const message = "Hola, tengo una duda en Tuin Asesores. ¿Me pueden ayudar?";
        return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(message)}`;
      },
      shouldShow() {
        const propiedadRegex = /^\/propiedad\/\d+$/;
        return !this.excludedRoutes.includes(this.$route.path) && !propiedadRegex.test(this.$route.path);
      },
    },
  };
  </script>