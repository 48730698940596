<template>
    <div v-if="isVisible" class="modal-overlay" style="display: flex; justify-content: center; align-items: center; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5);">
      <div class="modal-content" style="display: flex; flex-direction: column; width: 623px; padding-bottom: 26px; background: white; border-radius: 8px;">
        <!-- Modal Header -->
        <div class="modal-header" style="display: flex; justify-content: space-between; align-items: center; padding: 16px;">
          <h3>¿Seguro que deseas eliminar esta propiedad?</h3>
          <button type="button" class="btn-close" @click="$emit('cancel')" style="background: none; border: none; font-size: 20px;">×</button>
        </div>
        <div class="modal-header" style="padding: 16px; display: flex; align-items: center; gap: 4px; justify-content: flex-start;">
          <p style="margin:0;">Una vez eliminada, la propiedad no será visible para los usuarios del portal y esta acción no se podrá deshacer.</p>
        </div>
        <div class="modal-footer" style="display: flex; justify-content: flex-end; align-items: center; gap: 10px; padding: 10px 16px;">
          <button @click="$emit('confirm')" style="padding: 10px 16px; border-radius: 4px; background: var(--Primary-Primary, #FF4265); color: #fff; border: none;">Confirmar</button>
          <button @click="$emit('cancel')" style="padding: 10px 16px; border-radius: 4px; background: var(--Neutral-Ne-Medium-Weak, #737373); color: #fff; border: none;">Cancelar</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true
      }
    }
  }
  </script>
  
  <style>
    .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    }

    .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    }

    .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    }

    .modal-actions .btn {
    padding: 8px 16px;
    }

    /* Estilos del modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  /* Modal Header */
  .modal-header {
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  </style>

  