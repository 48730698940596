<template>
  <div>
    <div
      v-if="loader"
      class="fixed inset-0 flex items-center justify-center bg-black z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      />
    </div>
    <!-- Mapa del sitio  -->
    <div class="flex flex-wrap mt-10">
      <div class="flex flex-1">
        <fwb-heading tag="h2"> Prospectos </fwb-heading>
      </div>
      <div class="flex flex-1 justify-end items-end">
        <fwb-breadcrumb>
          <fwb-breadcrumb-item href="/customers">
            Lista de prospectos
          </fwb-breadcrumb-item>
        </fwb-breadcrumb>
      </div>
    </div>
    <!-- Mapa del sitio  -->

    <div class="flex justify-end mt-4">
      <button
        class="font-bold rounded-lg text-sm w-full sm:w-48 h-10 bg-tuin_base-0 text-white flex items-center justify-center"
        @click="addProspect('new')"
      >
        <img
          src="@/assets/imgs/icons/prospect.svg"
          alt="Nuevo prospecto"
          class="w-6 h-6 mr-2"
        />
        <span>Nuevo prospecto</span>
      </button>
    </div>

    <div class="rounded-md bg-white shadow-xl mt-4 p-4">
      <div
        class="relative overflow-x-auto shadow-md rounded-lg t w-full h-screen"
      >
      <table class="table-auto w-full text-left">
        <thead class="uppercase bg-[#ff4265] text-[#e5e7eb] text-sm">
          <tr>
            <!-- En pantallas pequeñas, "Nombre completo" se muestra, en pantallas grandes "Nombre" y "Apellidos" se mantienen separados -->
            <th class="py-2 px-4 border text-center font-bold sm:hidden">Nombre completo</th>
            <th class="py-2 px-4 border text-center font-bold hidden sm:table-cell">Nombre</th>
            <th class="py-2 px-4 border text-center font-bold hidden sm:table-cell">Apellidos</th>
            <th class="py-2 px-4 border text-center font-bold hidden sm:table-cell">Email</th>
            <th class="py-2 px-4 border text-center font-bold hidden sm:table-cell">Teléfono</th>
            <th class="py-2 px-4 border text-center font-bold">Contactar</th>
            <th class="py-2 px-4 border text-center font-bold">&nbsp;</th>
          </tr>
        </thead>
        <tbody class="bg-white text-[#646464]">
          <tr v-for="(row, index) in list" v-show="list.length > 0" :key="index">
            <!-- Nombre completo en pantallas pequeñas -->
            <td class="py-2 px-4 border sm:hidden">
              {{ row.name }} {{ row.last_name }} {{ row.second_last_name }}
            </td>

            <!-- Nombre y Apellidos en pantallas grandes -->
            <td class="py-2 px-4 border hidden sm:table-cell">{{ row.name }}</td>
            <td class="py-2 px-4 border hidden sm:table-cell">{{ row.last_name }} {{ row.second_last_name }}</td>

            <!-- Email y Teléfono ocultos en pantallas pequeñas -->
            <td class="py-2 px-4 border hidden sm:table-cell">{{ row.email }}</td>
            <td class="py-2 px-4 border hidden sm:table-cell">{{ row.phone }}</td>

            <td class="py-2 px-4 border text-center flex flex-wrap gap-2 justify-center">
              <a :href="`tel:+52${row.phone}`">
                <button class="bg-tuin_base-0 p-2 text-white rounded-lg">
                  <img src="@/assets/imgs/icons/phone.svg" alt="Teléfono" class="w-5 h-5" />
                </button>
              </a>
              <a :href="`https://wa.me/52${row.phone}?text=Hola%20${row.name}`" target="_blank">
                <button class="bg-tuin_base-0 p-2 text-white rounded-lg">
                  <img src="@/assets/imgs/icons/wp.svg" alt="Whatsapp" class="w-5 h-5" />
                </button>
              </a>
            </td>

            <td class="py-2 px-4 border text-center">
              <fwb-dropdown placement="left">
                <template #trigger>
                  <img src="@/assets/imgs/icons/points.png" alt="Opciones" class="cursor-pointer w-6 h-6" />
                </template>
                <fwb-list-group>
                  <fwb-list-group-item
                    class="cursor-pointer hover:bg-tuin_base-0 hover:text-white"
                    @click="addProspect('edit', row.id)"
                  >
                    Editar
                  </fwb-list-group-item>
                  <fwb-list-group-item
                    class="cursor-pointer hover:bg-tuin_base-0 hover:text-white"
                    @click="AssignProperty(row.id)"
                  >
                    Asignar propiedad
                  </fwb-list-group-item>
                  <fwb-list-group-item
                    class="cursor-pointer hover:bg-tuin_base-0 hover:text-white"
                    @click="ModalConfirmDelete(row.id)"
                  >
                    Eliminar
                  </fwb-list-group-item>
                </fwb-list-group>
              </fwb-dropdown>
            </td>
          </tr>
          <tr v-show="list.length == 0">
            <td colspan="6" class="text-center py-4">
              <div class="m-6 flex justify-center items-center">
                Aún no has dado de alta prospectos.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>

    <LeadFormView
      v-if="mostrarModal"
      :lead-id="prospectId"
      :is-show-modal="mostrarModal"
      @cerrar="CloseFormModal"
    />

    <ModalPropertiesComponent
      v-if="mostrarModalProperties"
      :prospect-id="prospectId"
      @close="ClosePropertiesModal"
    />
  </div>
</template>

<script setup>
import {
  FwbBreadcrumb,
  FwbBreadcrumbItem,
  FwbHeading,
  FwbDropdown,
  FwbListGroup,
  FwbListGroupItem,
} from "flowbite-vue";
//import LoaderTuin from "@/commond/LoaderTuin.vue";
import LeadFormView from "@/components/leads/LeadFormView.vue";
import ModalPropertiesComponent from "@/components/leads/ModalPropertiesComponent.vue";

//import InfoGralComponent from "@/components/leads/InfoGralComponent.vue";
import axios from "axios";
import { ref, onMounted, computed } from "vue";

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

let loader = ref(false);
let list = ref([]);
let formModalOpen = ref(false);
let prospectId = ref("");

let mostrarModal = ref(false);
let mostrarModalProperties = ref(false);

function addProspect(action, prospect_id) {
  if (action == "edit") {
    prospectId.value = prospect_id;
  }

  mostrarModal.value = true;
}

function ModalConfirmDelete() {
  console.log("debe confirmar la eliminación");
}

function AssignProperty(leadId) {
  mostrarModalProperties.value = true;
  prospectId.value = leadId;
}

function CloseFormModal(isUpdate = false) {
  formModalOpen.value = 0;
  prospectId.value = "";
  mostrarModal.value = false;
  if (isUpdate) {
    loader.value = true;
    getLeads();
  }
}

async function getLeads() {
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/broker/leads/${session.value.id}`,
      options
    );

    if (response.status == 401) {
      store.dispatch("clearUserSession");
    }

    if (response.status == 200) {
      list.value = response.data.resp;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }

  loader.value = false;
}

function ClosePropertiesModal() {
  mostrarModalProperties.value = false;
}

onMounted(async () => {
  loader.value = true;
  await getLeads();
});
</script>

<style scoped>
.icon-svg {
  font-size: #fff;
}
</style>
