<template>
  <div class="mt-4">
    <fwb-heading tag="h3">
      Características
    </fwb-heading>
    <div class=" mt-4">
      <ul class="grid grid-cols-2 md:grid-cols-4 gap-4">
        <!-- INfo manual -->
        <li v-if="property.age" class="flex items-start">
          <div class="bg-tuin_base-0 p-2 rounded-full">
            <img
              src="@/assets/imgs/icons/grua.svg"
              :alt="antigüedad"
              width="30"
            />
          </div>
          <div class="ml-2 min-w-0">
            <div class="font-bold">Antigüedad</div>
            <div>{{ property.age }} años</div>
          </div>
        </li>

        <li v-if="property.constructed_metres" class="flex items-start">
          <div class="bg-tuin_base-0 p-2 rounded-full">
            <img
              src="@/assets/imgs/icons/meters_w.svg"
              :alt="Metros"
              width="30"
            />
          </div>
          <div class="ml-2 min-w-0">
            <div class="font-bold">Metros</div>
            <div>{{ property.constructed_metres }} &#x33A1;</div>
          </div>
        </li>

        <li v-if="property.meters_build" class="flex items-start">
          <div class="bg-tuin_base-0 p-2 rounded-full">
            <img
              src="@/assets/imgs/icons/construccion.svg"
              :alt="Construcción"
              width="30"
            />
          </div>
          <div class="ml-2 min-w-0">
            <div class="font-bold">Construcción</div>
            <div>{{ property.meters_build }} &#x33A1;</div>
          </div>
        </li>

        <li v-if="property.floor" class="flex items-start">
          <div class="bg-tuin_base-0 p-2 rounded-full">
            <img
              src="@/assets/imgs/icons/floor.svg"
              :alt="pisos"
              width="30"
            />
          </div>
          <div class="ml-2 min-w-0">
            <div class="font-bold">Pisos</div>
            <div>{{ property.floor }}</div>
          </div>
        </li>

        <li v-if="property.bedrooms" class="flex items-start">
          <div class="bg-tuin_base-0 p-2 rounded-full">
            <img
              src="@/assets/imgs/icons/habitacion.svg"
              :alt="Habitaciones"
              width="30"
            />
          </div>
          <div class="ml-2 min-w-0">
            <div class="font-bold">Habitaciones</div>
            <div>{{ property.bedrooms }}</div>
          </div>
        </li>

        <li v-if="property.bathrooms" class="flex items-start">
          <div class="bg-tuin_base-0 p-2 rounded-full">
            <img
              src="@/assets/imgs/icons/shower_w.svg"
              :alt="Baños"
              width="30"
            />
          </div>
          <div class="ml-2 min-w-0">
            <div class="font-bold">Baños</div>
            <div>{{ property.bathrooms }}</div>
          </div>
        </li>

        <li v-if="property.half_bathrooms" class="flex items-start">
          <div class="bg-tuin_base-0 p-2 rounded-full">
            <img
              src="@/assets/imgs/icons/toilet.svg"
              alt="Medio Baños"
              width="30"
            />
          </div>
          <div class="ml-2 min-w-0">
            <div class="font-bold">Medio baños</div>
            <div>{{ property.half_bathrooms }}</div>
          </div>
        </li>

        <li v-if="property.parking" class="flex items-start">
          <div class="bg-tuin_base-0 p-2 rounded-full">
            <img src="@/assets/imgs/icons/parking_w.svg" alt="Estacionamiento" width="30" />
          </div>
          <div class="ml-2 min-w-0">
            <div class="font-bold">Estacionamiento</div>
            <div>{{ property.parking }}</div>
          </div>
        </li>

        <!-- Info manual -->
        <li v-for="item in props.list" :key="item.id" class="flex items-start">
          <div v-html="item.catalogue_character.icon" cclass="bg-tuin_base-0 p-2 rounded-full"></div>
          <div class="ml-2 min-w-0">
            <span class="font-bold">{{ item.catalogue_character.name }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { FwbHeading } from "flowbite-vue";
const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
  property: {
      type: Object,
      required: true,
      default: null
    }
});
</script>
