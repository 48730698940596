<template>
  <div>
    <div class="flex">
      <ToastAlert
        :is-active="toastAlert"
        :type="typeMsg"
        :message="msg"
      />
    </div>

    <div
      class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
    >
      <div class="flex flex-1 justify-center items-center mt-0 p-2">
        <fwb-input
          v-model="property_name"
          label="Nombre de propiedad *"
          placeholder="Ingresa el nombre para la propiedad"
          class="w-full pt-[-10px]"
        />
      </div>

      <div class="flex flex-1 justify-center items-center mt-5 p-2">
        <fwb-select
          v-model="type_property_id"
          :options="dic_properties"
          class="w-full"
          size="sm"
          label="Tipo de propiedad *"
          placeholder="Selecciona tipo de propiedad"
        />
      </div>

      <div class="flex flex-1 justify-center items-center mt-5 p-2">
        <fwb-select
          v-model="type_movement_id"
          :options="dic_movements"
          class="w-full"
          label="Tipo de transacción *"
          placeholder="Selecciona una opción "
        />
      </div>
    </div>

    <div
      class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
    >
      <div class="flex flex-1 items-center mt-0 p-2">
        <div class="flex flex-col ">
          <!-- Etiqueta dinámica -->
          <label
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ type_property_id === 9 ? 'Fecha de disponibilidad *' : 'Fecha de disponibilidad' }}
          </label>

          <!-- Componente VueDatePicker -->
          <div class="flex relative pt-[-5px]">
            <VueDatePicker
              v-model="date_available"
              :enable-time-picker="false"
              :locale="es"
              class="w-full"
            />
          </div>
        </div>
      </div>

      <div
        v-show="type_movement_id == 1 || type_movement_id == 3 || type_movement_id == 5"
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >
        <div class="w-full mt-[-1]">
          <label
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Valor de venta *
          </label>
          <div class="flex relative pt-[-5px]">
            <InputNumber 
              v-model="property_value" 
              input-id="property_value" 
              mode="currency" 
              currency="USD" 
              locale="en-US" 
            />
          </div>          
        </div>
      </div>

      <div
        v-show="type_movement_id == 2 || type_movement_id == 3 || type_movement_id == 4"
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >
        <div class="w-full mt-[-1]">
          <label
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Valor de renta *
          </label>
          <div class="flex relative pt-[-5px]">
            <InputNumber 
              v-model="rent_value" 
              input-id="rent_value" 
              mode="currency" 
              currency="USD" 
              locale="en-US"               
            />
          </div>          
        </div>
      </div>
      <div
        v-show="type_movement_id == 1 || type_movement_id == 2 || type_movement_id == 3 || type_movement_id == 4 || type_movement_id == 5"
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >
        <div class="w-full mt-[-1]">
          <div >
            <fwb-select
              v-model="currency"
              :options="currencyOptions"
              class="w-full text-gray-800 bg-white border-gray-300"
              label="Tipo de Moneda *"
              placeholder="Selecciona una moneda"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="![ 4, 6, 9 ].includes( type_property_id ) "
      class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
    >
      <div class="flex flex-1 justify-start items-star mt-5 p-2">
        <fwb-checkbox
          v-model="include_maintenance"
          label="Incluye mantenimiento"
        />

        <div
          v-show="!include_maintenance "
          class="w-50 ml-9"
        >
          <div class="w-full mt-[-1]">
            <label
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Costo de mantenimiento *
            </label>
            <div class="flex relative pt-[-5px]">
              <InputNumber 
                v-model="maintenance_value" 
                input-id="maintenance_value" 
                mode="currency" 
                currency="USD" 
                locale="en-US"               
              />
            </div>          
          </div>
        </div>
      </div>
    </div>

    <div 
      v-show="type_movement_id" 
      class="flex flex-col w-full" 
    >
      <div class="bg-[#e99236] text-xs sm:text-base text-white p-5 ml-5 mr-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="float-left"
          width="25"
          height="25"
          viewBox="0 0 24 24"
        >
          <path
            fill="#fff"
            d="M12 2L1 21h22M12 6l7.53 13H4.47M11 10v4h2v-4m-2 6v2h2v-2"
          />
        </svg>
        <p class="float-left">
          Colabora con otros asesores para vender más rápido y recibir más
          clientes potenciales al compartir comisión.
        </p>
      </div>
    </div>

    <div 
      v-if="type_movement_id == 1 || type_movement_id == 3" 
      class="flex flex-col md:flex-row w-full mt-10 ml-5"
    >
      <div class="flex flex-col w-full md:w-1/3">
        <label
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Compartes comisión de venta
        </label>
        <div class="flex w-full">
          <fwb-radio 
            v-model="share_commission" 
            label="Sí" 
            value="true" 
          />
          <fwb-radio 
            v-model="share_commission" 
            label="No" 
            value="false" 
          />
        </div>
      </div>

      <div 
        v-show="share_commission == 'true' " 
        class="flex flex-col w-full md:w-2/3 mt-4 md:mt-0 mr-0 md:mr-10"
      >
        <div class="flex w-full">
          <fwb-range
            v-model="commission_value"
            size="lg"
            :max="100"
            :min="1"
            label="Porcentaje sobre el valor total de la operación"
          />
        </div>
        <div class="flex w-full mt-4">
          Porcentaje a compartir: <strong>{{ commission_value + "%" }}</strong>
        </div>
      </div>
    </div>

    <div 
      v-show="type_movement_id == 2 || type_movement_id == 3" 
      class="flex flex-col md:flex-row w-full mt-10 ml-5"
    >
      <div class="flex flex-col w-full md:w-1/3">
        <label
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Compartes comisión de renta
        </label>
        <div class="flex w-full">
          <fwb-radio 
            v-model="share_commission_rent" 
            label="Si" 
            value="true" 
          />
          <fwb-radio 
            v-model="share_commission_rent" 
            label="No" 
            value="false" 
          />
        </div>
      </div>

      <div 
        v-show="share_commission_rent == 'true' " 
        class="flex flex-col w-full md:w-2/3 mt-4 md:mt-0 mr-0 md:mr-10"
      >
        <div class="flex w-full">
          <fwb-range
            v-model="commission_value_rent"
            size="lg"
            :max="100"
            :min="1"
            label="Porcentaje sobre el valor total de la operación"
          />
        </div>
        <div class="flex w-full mt-4">
          Porcentaje a compartir: <strong>{{ commission_value_rent + "%" }}</strong>
        </div>
      </div>
    </div>

    <div class="p-2 flex flex-col-1 justify-end items-end">
      <fwb-button
        class="bg-tuin-100"
        @click="save()"
      >
        Guardar
      </fwb-button>
    </div>
  </div>
</template>
<script setup>
import { es } from "date-fns/locale";
import VueDatePicker from "@vuepic/vue-datepicker";


import ToastAlert from "@/commond/ToastAlert.vue";
import axios from "axios";
import InputNumber from 'primevue/inputnumber';

import {
  FwbRange,
  FwbInput,
  FwbSelect,
  FwbCheckbox,
  FwbButton,
  FwbRadio,
} from "flowbite-vue";

import { ref, onMounted, computed, watch, defineEmits } from "vue";
const props = defineProps({
  resp: {
    type: Object,
    required: true,
    default: () => ({ property_name: '' })
  },
  propertyId: {
    type: Number,
    required: true,
    default: 0
  }
});


import { useRouter } from "vue-router";
let router = useRouter();
import { useRoute } from 'vue-router';
const route = useRoute();
import { useStore } from "vuex";
let store = useStore();

let session = computed(() => store.getters.getUserSession);
//import axios from "axios";

let property_name         = ref();
let type_movement_id      = ref(1);
let type_property_id      = ref("");
let property_value        = ref("");
let rent_value            = ref("");
let date_available        = ref("");
let include_maintenance   = ref(false);
let maintenance_value     = ref(false);

let share_commission = ref('true');
let commission_value = ref(1);

let share_commission_rent = ref('true');
let commission_value_rent = ref(1);

//let dic_movements_comission

// Definimos la variable reactiva que controla la visibilidad de la modal.
let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

let dic_properties = ref([]);
let dic_movements = ref([]);
let currency = ref(null); // Variable para la moneda seleccionada
let currencyOptions = ref([]);

let resp_property_id = ref(0);
const emit = defineEmits(['triggerUpdate']);
// Función auxiliar para ajustar la zona horaria
function adjustTimezone(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}
async function save() {
  
  let validation = await validate_form();
  
  if(!validation.status){
    showToast('danger', validation.message )
    return;
  }

  let post_params = {
    property_name:          property_name.value,
    type_movement_id:       type_movement_id.value,
    type_property_id:       type_property_id.value,
    property_value:         property_value.value,
    rent_value:             rent_value.value,
    ...(date_available.value && { 
      date_available: (() => {
        const date = new Date(date_available.value);
        // Asegurarse de que la fecha esté en UTC
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return utcDate.toISOString().split('T')[0];
      })(),
    }),
    include_maintenance:    include_maintenance.value,
    maintenance_value:      maintenance_value.value,
    currency:               currency.value?.name || '',
    currency_code:          currency.value?.code || '',
    share_commission:       share_commission.value,
    commission_value:       commission_value.value,
    share_commission_rent:  share_commission_rent.value,
    commission_value_rent:  commission_value_rent.value,
    broker_id:               session.value.id
  }

  if(props.propertyId != 0 ){
    post_params['property_id'] = props.propertyId
  }

  if(resp_property_id.value != 0 ){
    post_params['property_id'] = resp_property_id.value;
  }

  let url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${session.value.id}/save_info_general`;

  let options = {
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${session.value.token_api}`
    },
  };

  try {
    const response = await axios.post( url, post_params, options);
    
    if (response.status === 200) {       
      
      showToast('success', response.data.message )
      emit('triggerUpdate', response.data.resp.id );
      router.push({ name: "propertyFormEdit", params: { id: response.data.resp.id } });
      //window.location.href = `/Propiedades/form/${response.data.resp.id }`;
      
    }else{
      console.log('entro al else ', response.status)
    }
  } catch (error) {
    console.log('entro en el error', error)
    if(error.response && error.response.status === 401){
      store.dispatch("clearUserSession");
      router.push({ name: "login" });
    }
  }
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

async function showToast(type, message){
  toastAlert.value = true;
  typeMsg.value = type;
  msg.value = message;

  setTimeout(() => {
    closeToast()
  }, 3000);
}
 
async function validate_form() {
  
  if(property_name.value  == undefined || property_name.value  == ''){
    return { "status": false, "message": "El campo nombre de propiedad es obligatorio."}    
  }

  if(type_property_id.value  == undefined || type_property_id.value  == ''){
    return { "status": false, "message": "El campo tipo de propiedad es obligatorio."}    
  }

  if(type_movement_id.value  == undefined || type_movement_id.value  == ''){
    return { "status": false, "message": "El campo tipo de transacción es obligatorio."}    
  }

  if(type_movement_id.value == 1 || type_movement_id.value == 3){
    if(property_value.value  == undefined || property_value.value  == ''){
      return { "status": false, "message": "El campo valor de venta es obligatorio."}    
    }     
  }

  if(type_property_id.value === 9 && (!date_available.value)) {
    return { "status": false, "message": "El campo fecha de disponibilidad es obligatorio para este tipo de propiedad."}    
  }

  if(!currency.value) {
    return { "status": false, "message": "El campo tipo de moneda es obligatorio."};
  }

  if(type_movement_id.value == 2 || type_movement_id.value == 3){
    if(rent_value.value  == undefined || rent_value.value  == ''){
      return { "status": false, "message": "El campo valor de renta es obligatorio."}    
    }     
  }

  // if(!include_maintenance.value){
  //   if(maintenance_value.value  == undefined || maintenance_value.value  == ''){
  //     return { "status": false, "message": "El campo costo de mantenimiento es obligatorio."}    
  //   }     
  // }

  return { "status": true, "message": "Todo esta bien "}    

}

async function getDicProperties() {
  let url = `${process.env.VUE_APP_API_BASE_URL}/catalogue/type_property`;
  let options = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.get(url, options);

  if (response.status == 401) {
    store.dispatch("clearUserSession");
  }

  if (response.status == 200) {
    const formattedData = response.data.resp.map((item) => ({
      value: item.id,
      name: item.name,
    }));
    dic_properties.value = formattedData;
    // filteredProperties.value = response.data.resp;
  }
}

async function getDicTypeMovement() {
  let url = `${process.env.VUE_APP_API_BASE_URL}/catalogue/type_movement_property`;
  let options = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.get(url, options);

  if (response.status == 401) {
    store.dispatch("clearUserSession");
  }

  if (response.status == 200) {
    const formattedData = response.data.resp.map((item) => ({
      value: item.id,
      name: item.name,
    }));
    dic_movements.value = formattedData;
    // filteredProperties.value = response.data.resp;
  }
}

async function getCurrecies() {
  let url = `${process.env.VUE_APP_API_BASE_URL}/catalogue/type_currencies`;
  let options = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(url, options);

    if (response.status == 401) {
      store.dispatch("clearUserSession");
    }

    if (response.status == 200) {
      currencyOptions.value = response.data.resp.map(currency => ({
        value: currency,
        name: `${currency.name} (${currency.code})`
      }));

      // Si tenemos datos iniciales, establecemos el valor de currency
      if (props.resp && props.resp.currency) {
        const foundCurrency = currencyOptions.value.find(
          option => option.value.name === props.resp.currency
        );
        if (foundCurrency) {
          currency.value = foundCurrency.value;
        }
      }
    }
  } catch (error) {
    console.error('Error al cargar las monedas:', error);
  }
}

onMounted(async () => {
  await getDicProperties();
  await getDicTypeMovement();
  await getCurrecies();
  const input_sale = document.getElementById('property_value')
        
  if (input_sale) {
    input_sale.className = 'w-full pt-[-10px] bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2.5 text-sm'
  }

  const input_rent = document.getElementById('rent_value')
        
  if (input_rent) {
    input_rent.className = 'w-full pt-[-10px] bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2.5 text-sm'
  }

  const input_maintenance_value = document.getElementById('maintenance_value')
        
  if (input_maintenance_value) {
    input_maintenance_value.className = 'w-full pt-[-10px] bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2.5 text-sm'
  }

});

watch(
  () => props.resp,
  (newVal) => {
    property_name.value         = newVal ? newVal.property_name : ''; // Asigna el nuevo valor si existe
    type_movement_id.value      = newVal ? newVal.type_movement_id: '';
    type_property_id.value      = newVal ? newVal.type_property_id: '';
    property_value.value        = newVal ? newVal.property_value: '';
    rent_value.value            = newVal ? newVal.rent_value: '';
    date_available.value = newVal && newVal.date_available ? adjustTimezone(newVal.date_available) : '';
    include_maintenance.value   = newVal && newVal.include_maintenance == 1 ? true:false;
    maintenance_value.value     = newVal ? newVal.maintenance_value: '';
      // Nuevo manejo para currency
      if (newVal && newVal.currency && currencyOptions.value.length > 0) {
      // Buscamos la opción que coincida con el nombre de la moneda
      const foundCurrency = currencyOptions.value.find(
        option => option.value.name === newVal.currency
      );
      if (foundCurrency) {
        currency.value = foundCurrency.value;
      }
    }
    share_commission.value      = newVal && newVal.has_commission_sale==1?'true':'false';
    commission_value.value      = newVal && newVal.commission_sale_value!=undefined  ? newVal.commission_sale_value: 1;
    share_commission_rent.value = newVal && newVal.has_commission_rent==1?'true':'false';
    commission_value_rent.value = newVal && newVal.commission_rent_value!=undefined ? newVal.commission_rent_value: 1;
  },
  { immediate: true } // Ejecuta el watch inmediatamente al montar el componente
);
watch(
  () => route.params.id,
  (newId) => {
    if (newId) {
      resp_property_id.value = parseInt(newId);
    }
  },
  { immediate: true }  // Esto hace que se ejecute inmediatamente al montar el componente
);

</script>


<style scoped>
/* Animación personalizada */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
}
</style>