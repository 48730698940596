<template>
  <div>
    <fwb-card
      img-alt="Desk"
      variant="image"
      class="border-l-2 cursor-pointer"
      @click="goTo(props.property.property_id)"
    >
      <div class="w-full h-52 overflow-hidden">
        <LabelTag
          :label="props.property.type_movement_name"
          :type_movement_id="props.property.type_movement_id"
        />
        <div>
          <div
            v-if="props.property.image"
            :style="{ backgroundImage: `url(${props.property.image})` }"
            class="w-full h-[208px] bg-cover bg-center"
            :alt="props.property_name"
          >
            &nbsp;
          </div>

          <div v-if="!props.image">
            <img
              src="@/assets/imgs/preview_house.svg"
              :alt="props.property.property_name"
              class="w-full object-cover h-150"
            />
          </div>
        </div>
      </div>
      <div class="p-5">
        <h5
          class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
        >
          {{ props.property.property_name }}
        </h5>

        <p class="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
          {{ props.property.property_address }}
        </p>

        <div
          class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-4 p-2 mt-5"
        >
          <div class=" flex justify-center items-center">
            <img
              src="@/assets/imgs/icons/meters.svg"
              alt="metros construidos"
              width="25"
              class="float-left"
            >
            <span class=" float-left ml-2 text-sm">
              {{ props.property.constructed_metres }}
            </span>
          </div>
          <div class=" flex justify-center items-center">
            <img
              src="@/assets/imgs/icons/bedroom-outline.svg"
              alt="habitaciones"
              width="25"
              class="float-left"
            >
            <span class=" float-left ml-2 text-sm">{{ props.property.bedrooms }}</span>            
          </div>
          <div class=" flex justify-center items-center">
            <img
              src="@/assets/imgs/icons/shower.svg"
              alt="habitaciones"
              width="25"
              class="float-left"
            >
            <span class=" float-left ml-2 text-sm">
              {{ props.property.bathrooms }}
            </span>
          </div>
        </div>

        <div
          v-if="props.property.type_movement_id == 1 || props.property.type_movement_id == 3 || props.property.type_movement_id == 5"
          class="text-tuin-100 text-2xl font-bold"
        >
          {{ props.property.property_value }}
          <span class="text-sm">{{ props.property.currency_code || props.property.currency }} </span>
        </div>
        <div
          v-if="props.property.type_movement_id == 2 || props.property.type_movement_id == 3"
          class="text-tuin-100 text-2xl font-bold"
        >
          {{ props.property.rent_value }}
          <span class="text-sm">{{ props.property.currency_code || props.property.currency }} </span>
        </div>

        <div v-if="props.property.video_url" class="w-full h-[208px]">
            <video
              class="w-full h-full object-cover"
              controls
              :src="props.property.video_url"
              @error="handleVideoError"
            >
              Tu navegador no soporta la etiqueta de video.
            </video>
          </div>
      </div>
    </fwb-card>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { FwbCard } from "flowbite-vue";
import { useRouter } from "vue-router";
import LabelTag from "@/commond/LabelTag.vue";
let router = useRouter();

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  property: {
    type: Number,
    required: true,
    default: 0,
  },
});

async function goTo(property_id) {
  router.push({
    name: "property_detail",
    params: { property_id: property_id },
  });
}
</script>