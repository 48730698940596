<template>
  <div>
    <div
      v-if="loader"
      class="fixed inset-0 flex items-center justify-center bg-black  z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class=" w-[500px]"
      >
    </div>

    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />
    <div 
      v-if="!loader"
      class="flex min-h-screen bg-dark_bg-100 text-white h-full"
    >
      <div class="hidden w-1/2 sm:flex items-center justify-center">
        <div>
          <img
            src="@/assets/imgs/door.jpg"
            alt="Tuin hora de hacer magia"
            class="w-full h-full max-h-screen rounded-lg shadow-lg"
          >
        </div>
      </div>

      <div
        v-if="page_view == 'login'"
        class="w-full sm:w-1/2 flex flex-col items-center justify-center bg-black min-h-screen"
      >
        <img
          src="@/assets/imgs/LogoTuin.svg"
          alt="Logo"
          class="object-contain"
        >
        <h3 class="mb-6">
          Asesores 
        </h3>
        <!-- card -->
        <div class="w-3/4 max-w-md bg-dark_bg-100 p-5 rounded-sm">
          <h2 class="text-2xl font-semibold mb-6">
            Iniciar sesión
          </h2>
          <div class="mb-4">
            <fwb-input
              v-model="email"
              label="Email"
              type="email"
              required=""
              label-class="text-white"
              class="custom-label"
              placeholder="Introduce tu correo electrónico"
            />
          </div>
          <div class="mb-4">
            <fwb-input
              v-model="password"
              label="Contraseña"
              type="password"
              required=""
              label-class="text-white"
              class="custom-label"
              placeholder="Introduce tu contraseña"
            />
          </div>
          <div class="mb-4 flex items-center justify-between">
            <div>
              <!-- <fwb-input
                  v-model="remember"
                  placeholder="Test@gmail.com" 
                  label="Email"
                /> -->

              <fwb-checkbox v-model="remember">
                <fwb-a class="text-white">
                  Recordar contraseña
                </fwb-a>
              </fwb-checkbox>
            </div>
            
            <router-link
              to="/forgot-password"
              class="text-tuin-100 text-sm cursor-pointer"
            >
              ¿Olvidaste tu contraseña?
            </router-link>         
          </div>
          <button
            class="w-full py-2 bg-tuin-100 hover:bg-tuin-100 rounded-md text-white font-semibold"
            @click="auth()"
          >
            Ingresar
          </button>
          <div class="mt-4 text-center">
            <router-link
              to="/create-account"
              class="text-tuin-100 text-sm cursor-pointer"
            >
              Crear cuenta
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { FwbInput, FwbCheckbox } from "flowbite-vue";
import axios from "axios";
import { useRouter } from "vue-router";
let router = useRouter();
import ToastAlert from "@/commond/ToastAlert.vue";
import { useStore } from "vuex";
let store = useStore();


let page_view = ref("login");

let email = ref("");
let password = ref("");
let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

let loader = ref(false);
//let remember = ref("");

async function auth() {
  loader.value = true;
  if (email.value == "") {
    toastAlert.value = true;
    typeMsg.value = "danger";
    msg.value = "Debes agregar un email válido";
    loader.value = false;

    setTimeout(() => {
      closeToast();
    }, 3000);

    return false;
  }

  if (password.value == "") {
    toastAlert.value = true;
    typeMsg.value = "danger";
    msg.value = "Debes agregar tu contraseña";
    loader.value = false;

    setTimeout(() => {
      closeToast();
    }, 3000);

    return false;
  }

  
  let params = { email: email.value, password: password.value };
  // try {
    await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/login`,
      params
    ).then(function (response) {      
      store.dispatch("saveUserSession", response.data.resp);
      if (response.data.resp.step == 0) {
        loader.value = false;
        router.push({ name: "info_company" });
        // router.push({ name: "info_profile" });
      }else if (response.data.resp.step == 1) {
        loader.value = false;
        router.push({ name: "info_profile" });
        //router.push({ name: "info_company" });
      }else if (response.data.resp.step == 2) {
        loader.value = false;
        //router.push({ name: "dashboard" });
        router.push({ name: "info_networks" });
      }else if (response.data.resp.step == 3) {        
        // router.push({ name: "properties" });
        window.location.href = "/properties";
        // loader.value = false;
        // setTimeout(()=>{ loader.value = false; }, 2000)
      }else{        
        // router.push({ name: "properties" });
        window.location.href = "/properties";
        // setTimeout(()=>{ loader.value = false; }, 2000)
      }
    }).catch(function(error){

      const error_resp =  error.response;
      if(error_resp.status == 422){
        toastAlert.value = true;
        typeMsg.value = "danger";
        msg.value = error_resp.data.message;
        loader.value = false;
        setTimeout(() => {
          closeToast();
        }, 3000);
      }

    });
    

    
  // } catch (error) {
  //   if (error.response && error.response.status == 422) {
  //     toastAlert.value = true;
  //     typeMsg.value = "danger";
  //     msg.value = error.response.data.message;
  //     loader.value = false;
  //     setTimeout(() => {
  //       closeToast();
  //     }, 3000);
  //   }
  // }
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

</script>
  
<style scoped>
/* Si el label tiene clases predeterminadas de Flowbite/Tailwind, puedes sobrescribirlas */
::v-deep .custom-label label {
  @apply text-white;
}

/* También puedes apuntar directamente al selector completo */
::v-deep .custom-label label.block.mb-2.text-sm.font-medium {
  @apply text-white;
}

</style>