
vistaCopiar<template>
  <div class="w-full flex justify-center">
    <!-- inicio de contenido -->
    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-5 sm:mt-10"      
    >
      <template v-if="hasSlug">
        <fwb-tabs
          v-model="activeTab"
          class="p-5"
        >
          <fwb-tab
            name="first"
            title="Datos generales"
          >
            <FormAgentComponent
              :property-id="id"
              @trigger-update-property="updateProperty"
            />
          </fwb-tab>
          <fwb-tab
            name="ubication"
            title="Ubicación"
            :disabled="!active_tab"
          >
            <FormLocationComponent
              :resp="data_broker"
              :property-id="id"
              Type="agent" 
            />
          </fwb-tab>
        </fwb-tabs>
      </template>
      <template v-else>
        <div class="p-5">
          <FormAgentComponent
            :property-id="id"
            @trigger-update-property="updateProperty"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>

import {
  FwbTab,
  FwbTabs,
} from "flowbite-vue";
import { ref, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import FormAgentComponent from "./FormAgentComponent.vue";
import FormLocationComponent from "./FormLocationComponent.vue";
import { useStore } from "vuex";
import axios from "axios";
const store = useStore();
let session = computed(() => store.getters.getUserSession);
// Acceder al parámetro 'id' de la URL directamente
const route = useRoute();
let id = session.value.id;
let active_tab = ref(true);
let router = useRouter();
const activeTab = ref("first");
let step = ref(3);
let data_broker = ref({});
const hasSlug = ref(false); 

onMounted(async () => {
  console.log('[ID PROPIEDAD]',id)
  if (id != undefined) {
    active_tab.value = true;
  }
});

async function getProfile() {
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };
    const response = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}`,
      options
    );
    console.log(response)

    if (response.status == 200) {
      data_broker.value = response;
      console.log(response)
    }
  } catch (error) {
    let response = error.response ;
    if(response.status == 401){
      store.dispatch("clearUserSession"); 
      router.push({ name: 'login' });
    }
    console.error("Error fetching data:", error.response);
  }
}

async function updateProperty(property_id){
  console.log('actualiza estado de tabs', property_id)
  active_tab.value = true;
}
onMounted(async () => {
    try {
      await getProfile();
      // Modificamos la ruta para acceder correctamente al slug
      hasSlug.value = data_broker.value?.data?.resp?.info?.slug ? true : false;
      step.value = session.value.step;
    } catch (error) {
      console.error("Error al intentar traer los datos", error);
    }
});
watch(activeTab, async (newTab) => {
    if (newTab === 'first') {
      await getProfile(); // Al seleccionar "Datos generales", cargamos los datos de la compañía
    }
  });
watch(
  () => route.params.id,
  (newId) => {
    console.log('[-----]',newId)
      id =  newId
  }
);

</script>