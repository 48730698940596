<template>
  <div>
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />
    <div class="flex min-h-screen bg-dark_bg-100 text-white h-full">
      <div class="w-1/2 flex items-center justify-center">
        <div>
          <img
            src="@/assets/imgs/door.jpg"
            alt="Tuin hora de hacer magia"
            class="w-full h-full max-h-screen rounded-lg shadow-lg"
          >
        </div>
      </div>

      <div
        class="w-full sm:w-1/2 flex flex-col items-center justify-center bg-black min-h-screen"
      >
        <img
          src="@/assets/imgs/LogoTuin.svg"
          alt="Logo"
          class="object-contain"
        >
        <h3 class="mb-6">
          Asesores
        </h3>
        <!-- card -->
        <div class="w-3/4 max-w-md bg-dark_bg-100 p-5 rounded-sm">
          <h2 class="text-2xl font-semibold mb-6">
            Crear cuenta
          </h2>
          <div class="mb-4">
            <fwb-input
              v-model="email"
              label="Email"
              type="email"
              required=""
              label-class="text-white"
              class="custom-label"
              placeholder="Introduce tu correo electrónico"
            />
          </div>
          <div class="mb-4">
            <fwb-input
              v-model="password"
              label="Contraseña"
              type="password"
              required=""
              label-class="text-white"
              class="custom-label"
              placeholder="Introduce tu contraseña"
            />
          </div>

          <button
            class="w-full py-2 bg-tuin-100 hover:bg-tuin-100 rounded-md text-white font-semibold"
            @click="RegisterAccount()"
          >
            Registrar
          </button>
          <div class="mt-4 text-center">
            <router-link
              to="/login"
              class="text-tuin-100 text-sm cursor-pointer"
            >
              Ya tengo una cuenta
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import ToastAlert from "@/commond/ToastAlert.vue";
import { FwbInput } from "flowbite-vue";
import {
  ref,
  //defineProps
} from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
let router = useRouter();

// const props = defineProps({
//   changeView: Function
// });

let email = ref("");
let password = ref("");
let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

async function RegisterAccount() {
  let params = {
    email: email.value,
    password: password.value,
    type_account: 3,
  };
  
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/create_account`,
      params
    );

    if (response.status == 401) {
      console.log("Hay que sacarlo de la sesion ");
    }

    if (response.status == 200) {
      // properties.value = response.data.resp;
      // filteredProperties.value = response.data.resp;
        toastAlert.value = true;
        typeMsg.value = "success";
        msg.value = "Cuenta creada correctamente, en breve recibirás un correo de confirmación";

        setTimeout(() => {
            closeToast();
            router.push({ name: "login" });
        }, 3000);      
    }
  } catch (error) {
    if(error.response.status == 422){
      console.log(error.response.data)
      const resp = error.response.data;

      for (const field in resp.errors) {
        console.log(field);
        resp.errors[field].forEach((message) => {
            console.log(message)
            toastAlert.value = true;
              typeMsg.value = "danger";
              msg.value = message;

              setTimeout(() => {
                  closeToast();
              }, 3000);
        });
      }      
    }
  }
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

</script>

<style scoped>
/* Si el label tiene clases predeterminadas de Flowbite/Tailwind, puedes sobrescribirlas */
::v-deep .custom-label label {
  @apply text-white;
}

/* También puedes apuntar directamente al selector completo */
::v-deep .custom-label label.block.mb-2.text-sm.font-medium {
  @apply text-white;
}
</style>