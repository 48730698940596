<template>
    <div>
      <ToastAlert
        :is-active="toastAlert"
        :type="typeMsg"
        :message="msg"
      />
  
      <div v-show="!showModal">
        <div
          class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
        >
          <div class="flex flex-1 justify-center items-center mt-0 p-2">
            <div class="w-full mt-[-1]">
              <label
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Código postal
              </label>
              <div class="flex relative pt-[-5px]">
                <InputMask
                  id="zipcode"
                  v-model="zipcode"
                  mask="99999"
                  placeholder="99999"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-28 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2 text-sm"
                  fluid
                  @keypress="handleInput"
                  @input="handleInputChange"
                  @blur="handleZipCodeBlur"
                />
              </div>
            </div>
          </div>
        </div>
  
        <div
          class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
        >
          <div class="flex flex-1 justify-center items-center mt-0 p-2">
            <fwb-select
              id="state"
              v-model="state"
              :options="states"
              placeholder="Selecciona una opción"
              label="Estado *"
              class="w-full"
              @change="handleActiveMap"
            />
          </div>
  
          <div class="flex flex-1 justify-center items-center mt-0 p-2">
            <fwb-input
              v-model="city"
              label="Ciudad *"
              placeholder="Ingresa tu nombre"
              size="sm"
              class="w-full"
              @keyup="handleActiveMap"
            />
          </div>
        </div>
  
        <div
          class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
        >
          <div class="flex flex-1 justify-center items-center mt-0 p-2">
            <fwb-input
              v-model="municipality"
              label="Delegación / Municipio *"
              placeholder="ej. Miguel Hidalgo"
              size="sm"
              class="w-full"
              @keyup="handleActiveMap"
            />
          </div>
  
          <div class="flex flex-1 justify-center items-center mt-0 p-2">
            <fwb-select
              id="colony"
              v-model="colony"
              :options="colonies"
              placeholder="Selecciona una opción"
              label="Colonia *"
              class="w-full"
              @keyup="handleActiveMap"
            />
          </div>
        </div>
  
        <div
          class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
        >
          <div class="flex flex-1 justify-center items-center mt-0 p-2">
            <fwb-input
              v-model="street"
              :label=" [ 1,2,3,5,7,8 ].includes( type_property_id ) ? 'Calle *': 'Calle' "
              placeholder="ej. av. central "
              size="sm"
              class="w-full"
              @keyup="handleActiveMap"
            />
          </div>
  
          <div class="flex flex-1 justify-start items-start mt-4 p-2 ml-2">
            <div class="flex flex-1 justify-center items-center mt-0 p-2">
              <div class="flex flex-1 justify-start items-start">
                <fwb-input
                  v-model="no_ext"
                  :label=" [ 1,2,3,5,7,8 ].includes( type_property_id ) ? 'No. Ext. *': 'No. Ext.' "
                  placeholder="10"
                  size="sm"
                  class="w-full"
                  @keyup="handleActiveMap"
                />
              </div>
  
              <div class="flex flex-1 justify-center items-center mt-0 p-2">
                <fwb-input
                  v-model="no_int"
                  label="No. Int. "
                  placeholder="102"
                  size="sm"
                  class="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Botón de ubicacion -->
        <div class="flex flex-1 justify-start items-start mt-4 p-2 ml-2">
          <fwb-button
            color="default"
            :disabled="!isActiveLocation"
            @click="openModalMaps"
          >
            Ubica tu inmueble
          </fwb-button>
        </div>
      </div>
  
      <div
        v-if="showModal"
        class="fade-in"
      >
        <ModalMapsComponent
          :address="address"
          :export_lat="property_lt"
          :export_ln="property_ln"
          @update-location="handleLocationUpdate"
        />
        <!-- Botón de ubicacion -->
        <div class="flex flex-1 justify-start items-start mt-4 p-2 ml-2">
          <fwb-button
            color="default"
            @click="openModalMaps"
          >
            Regresar a datos de ubicación
          </fwb-button>
        </div>
      </div>
  
      <div class="p-2 flex flex-col-1 justify-end items-end"> 
          <fwb-button
            class="bg-tuin-100"
            @click="handleSave"
          >
            Guardar
          </fwb-button>
      </div>
    </div>
  </template>
  
  <script setup>
  import ToastAlert from "@/commond/ToastAlert.vue";
  import { FwbInput, FwbSelect, FwbButton } from "flowbite-vue";
  import InputMask from "primevue/inputmask";
  import {
    ref,
    onBeforeMount,
    computed,
    defineProps,
    watch,
    defineEmits,
  } from "vue";
  
  import ModalMapsComponent from "@/components/properties/ModalMapsComponent.vue";
  import axios from "axios";
  import { useStore } from "vuex";
  const store = useStore();
  let session = computed(() => store.getters.getUserSession);
  
  const props = defineProps({
    resp: {
      type: Object,
      required: true,
      default: () => ({ property_name: "" }),
    },
    propertyId: {
      type: Number,
      required: true,
      default: 0,
    },
    Type: {
      type: String,
      required: true,
    },
  });
  
  // Definimos la variable reactiva que controla la visibilidad de la modal.
  let toastAlert = ref(false);
  let typeMsg = ref("");
  let msg = ref("");
  
  let showModal = ref(false);
  let isActiveLocation = ref(false);
  let zipcode = ref("");
  
  let state = ref("");
  let municipality = ref("");
  let municipality_id = ref("");
  let city = ref("");
  let colony = ref("");
  let street = ref("");
  let no_int = ref("");
  let no_ext = ref("");
  let property_lt = ref("");
  let property_ln = ref("");
  let municipalities = ref([]);
  let states = ref([]);
  let colonies = ref([]);
  let address = ref("");
  
  const emit = defineEmits(["triggerUpdate"]);
  
  async function handleActiveMap() {
    if (
      zipcode.value != "" &&
      municipality.value != "" &&
      city.value != "" &&
      colony.value != "" &&
      street.value != "" &&
      no_ext.value != ""
    ) {
      isActiveLocation.value = true;
    }
  }
  
  // Configurar CKEditor
  function openModalMaps() {
    // Filtrar el objeto con id 9 y obtener el estado
    const estadoFiltrado = states.value.find(
      (estado) => estado.value === state.value
    );
  
    const index = colonies.value.findIndex(
      (item) => item.value === parseInt(colony.value)
    );
    const coloniesFiltrado = colonies.value[index].name;
    //let selected = colony.value.toString();
    // Obtener el texto de la opción seleccionada
    address.value = `${street.value} ${no_ext.value}, ${coloniesFiltrado},  ${municipality.value}, ${city.value}, ${estadoFiltrado.name}`;
  
    showModal.value = !showModal.value;
  }
  
  async function GetState() {
    let url_cnn = `${process.env.VUE_APP_API_BASE_URL}/catalogue/states`;
  
    var options = {
      headers: {
        "Content-Type": "application/json", // Es importante establecer el tipo de contenido para el envío de archivos
      },
    };
  
    axios
      .get(url_cnn, options)
      .then((response) => {
        var resp = response.data.resp;
        states.value = resp.map((estado) => {
          return {
            value: estado.id, // Usando el id como value
            name: estado.estado, // Usando el nombre del estado como name
          };
        });
      })
      .catch((error) => {
        console.log("+++", error.response);
        // if(error.response.status == 401){
        //     console.log('Debe cerrar la sesión')
        // }
      });
  }
  
  async function ConsultZip(zipCode) {
    let url_cnn = `${process.env.VUE_APP_API_BASE_URL}/catalogue/cp/${zipCode}`;
  
    var options = {
      headers: {
        "Content-Type": "application/json", // Es importante establecer el tipo de contenido para el envío de archivos
        //'Authorization': `Bearer ${session.value.token_api}` // Reemplaza tuTokenBearer con tu token real
      },
    };
  
    axios
      .get(url_cnn, options)
      .then((response) => {
        var resp = response.data.data;
        // verifica que el CP retorne datos
        if (resp.length > 0) {
          // Sacar el estado
          state.value = resp[0]["estado_id"];
  
          //asignar municipio
          municipalities.value = [
            {
              value: resp[0]["municipio_id"],
              name: resp[0]["municipio"],
            },
          ];
  
          municipality.value = resp[0]["municipio"];
          municipality_id.value = resp[0]["municipio_id"];
          city.value = resp[0]["ciudad"];
          
  
          //asignar colonia
          if (resp.length == 1) {
            // Solo aplica si nada mas me retorna un resultado
            if (colony.value == "") {
              colony.value = resp[0]["id"];
            }
          }
          
          colonies.value = resp.map((colony) => {
            return {
              value: colony.id, // Usando el id como value
              name: colony.colonia, // Usando el nombre del estado como name
            };
          });
        } else {
          toastAlert.value = true;
          typeMsg.value = "danger";
          msg.value = "Verifica tu código postal";
          setTimeout(() => {
            closeToast();
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          console.log("Debe cerrar la sesión");
        }
      });
  }
  
  // Modifica las funciones existentes y agrega las nuevas
  async function handleInput() {
    // Mantén la validación del keypress para navegadores desktop
    let cleanedZipcode = zipcode.value.replace(/_/g, "");
    if (cleanedZipcode.length == 5) {
      cleanedZipcode = cleanedZipcode.slice(0, 5);
      ConsultZip(zipcode.value);
      handleActiveMap();
      return false;
    }
  }
  
  // Nueva función para manejar cambios en el input
  async function handleInputChange() {
    let cleanedZipcode = zipcode.value.toString().replace(/_/g, "");
    if (cleanedZipcode.length == 5) {
      await ConsultZip(cleanedZipcode);
      handleActiveMap();
    }
  }
  
  // Nueva función para manejar el evento blur
  async function handleZipCodeBlur() {
    let cleanedZipcode = zipcode.value.toString().replace(/_/g, "");
    if (cleanedZipcode.length == 5) {
      await ConsultZip(cleanedZipcode);
      handleActiveMap();
    } else if (cleanedZipcode.length > 0) {
      // Mostrar mensaje de error si el código postal está incompleto
      toastAlert.value = true;
      typeMsg.value = "danger";
      msg.value = "El código postal debe tener 5 dígitos";
      setTimeout(() => {
        closeToast();
      }, 3000);
    }
  }
  
  
  async function closeToast() {
    toastAlert.value = false;
    typeMsg.value = "";
    msg.value = "";
  }
  
  // Función para manejar la actualización de la ubicación
  function handleLocationUpdate(location) {
    property_lt.value = location.lat_export;
    property_ln.value = location.lng_export;
  }
  const handleSave = () => {
      if (props.Type === 'company') {
          SaveCompanyAddress();
      } else if (props.Type === 'agent') {
          save();
      } else {
          console.error('Tipo desconocido:', props.Type);
      }
  };
  async function SaveCompanyAddress() {
    let post_params = {
        company_lt: property_lt.value,
        company_ln: property_ln.value,
        agent_id: session.value.id,
        zipcode: zipcode.value,
        state: state.value,
        city: city.value,
        municipality: municipality_id.value,
        neighborhood: colony.value,
        street: street.value,
        ext_number: no_ext.value,
        int_number: no_int.value
    };

    if (props.propertyId != 0) {
      post_params["property_id"] = props.propertyId;
    }
  
    let url = `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${props.propertyId}/address_company`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };
  
    try {
      const response = await axios.post(url, post_params, options);
  
      if (response.status == 401) {
        console.log("Hay que sacarlo de la sesion ");
      }
  
      if (response.status == 200) {
        console.log(response);
        showToast("success", response.data.message);
        // 🔹 Verificar que 'inputs' existe antes de acceder a 'id'
        if (response.data.inputs && response.data.inputs.id) {
          emit("triggerUpdate", response.data.inputs.id);
        } else {
          console.error("La respuesta no contiene 'inputs.id'", response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
  
      console.log(error.response.data);
      const resp = error.response.data;
  
      if (error.response && error.response.status == 422) {
        for (const field in resp.errors) {
          console.log(field);
          resp.errors[field].forEach((message) => {
            console.log(message);
            toastAlert.value = true;
            typeMsg.value = "danger";
            msg.value = message;
          });
        }
  
        setTimeout(() => {
          closeToast();
        }, 3000);
      }
    }
  }
  

  async function save() {
    let post_params = {
      user_address_lt: property_lt.value,
      user_address_ln: property_ln.value,
      zipcode: zipcode.value,
      state: state.value,
      municipality: municipality.value,
      city: city.value,
      colony: colony.value,
      street: street.value,
      no_int: no_int.value,
      no_ext: no_ext.value,
    };
  
    if (props.propertyId != 0) {
      post_params["property_id"] = props.propertyId;
    }
  
    let url = `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${props.propertyId}/save_location`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };
  
    try {
      const response = await axios.post(url, post_params, options);
  
      if (response.status == 401) {
        console.log("Hay que sacarlo de la sesion ");
      }
  
      if (response.status == 200) {
        console.log(response);
        showToast("success", response.data.message);
        emit("triggerUpdate", response.data.resp.id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
  
      console.log(error.response.data);
      const resp = error.response.data;
  
      if (error.response && error.response.status == 422) {
        for (const field in resp.errors) {
          console.log(field);
          resp.errors[field].forEach((message) => {
            console.log(message);
            toastAlert.value = true;
            typeMsg.value = "danger";
            msg.value = message;
          });
        }
  
        setTimeout(() => {
          closeToast();
        }, 3000);
      }
    }
  }
  
  async function showToast(type, message) {
    toastAlert.value = true;
    typeMsg.value = type;
    msg.value = message;
  
    setTimeout(() => {
      closeToast();
    }, 3000);
  }
  
  onBeforeMount(async () => {
    await GetState();
  });
  
  
  let type_property_id = ref(null);
  watch(
    () => props.resp,  // Observa el objeto 'resp'
    async (newVal) => { 
      let location;

      if (props.Type === "agent") {
        // Caso para agentes: obtenemos la ubicación desde 'location'
        location = newVal?.data?.resp?.location;
      } else if (props.Type === "company") {
        // Caso para compañías: obtenemos la información desde 'info'
        location = newVal?.data?.resp?.info;
      }

      if (location) {
        // Asignamos los valores según el tipo de datos recibido
        zipcode.value = location.zipcode || "";
        colony.value = location.neighborhood || "";
        state.value = location.state || "";
        municipality.value = location.municipality || "";
        city.value = location.city || "";
        street.value = location.street || "";
        no_int.value = location.int_number || "";
        no_ext.value = location.ext_number || "";
        
        // Propiedades específicas para agentes y compañías
        property_lt.value = props.Type === "agent" 
          ? location.user_address_lt || "" 
          : location.company_lt || "";
          
        property_ln.value = props.Type === "agent" 
          ? location.user_address_ln || "" 
          : location.company_ln || "";

        // Si el 'zipcode' está presente, realiza la consulta
        if (location.zipcode) {
          await ConsultZip(location.zipcode);
          handleActiveMap();
        }
      } else {
        console.error("No se encontraron datos de ubicación");
      }
    },
    { immediate: true }
  );

  </script>
    
    
    <style scoped>
  /* Animación personalizada */
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .animate-fade-in {
    animation: fade-in 0.5s ease-in-out;
  }
  </style>