<template>
    <div>
      <div>
        <LoaderTuin :is-loading="loader" />
      </div>
      <ToastAlert :is-active="toastAlert" :type="typeMsg" :message="msg" />
  
      <!-- Video input options -->
      <div class="mb-6">
        <div class="flex gap-4 mb-4">
          <!--<button 
            @click="activeInput = 'file'"
            :class="[
              'px-4 py-2 rounded-md',
              activeInput === 'file' 
                ? 'bg-blue-500 text-white' 
                : 'bg-gray-200 text-gray-700'
            ]"
          >
            Subir archivo
          </button>
          <button 
            @click="activeInput = 'url'"
            :class="[
              'px-4 py-2 rounded-md',
              activeInput === 'url' 
                ? 'bg-blue-500 text-white' 
                : 'bg-gray-200 text-gray-700'
            ]"
          >
            Agregar URL
          </button>-->
        </div>
  
        <!-- File upload dropzone 
        <div v-if="activeInput === 'file'">
          <div
            :class="[
              'border-4 border-dashed p-6 rounded-md cursor-pointer',
              dragging ? 'border-blue-500 bg-blue-100' : 'border-gray-300 bg-gray-50',
            ]"
            class="flex flex-col items-center justify-center space-y-4 p-4"
            @dragover.prevent
            @dragenter.prevent="dragging = true"
            @dragleave="dragging = false"
            @drop.prevent="handleDrop"
            @click="triggerFileInput"
          >
            <p class="text-gray-500 text-center">
              Arrastra y suelta el video aquí o haz clic para seleccionarlo
            </p>
            <p class="text-sm text-gray-400">
              Formatos admitidos: MP4, WebM (máximo 100MB)
            </p>
            <input
              ref="fileInput"
              type="file"
              class="hidden"
              accept="video/*"
              @change="handleFileSelect"
            />
          </div>
        </div>-->
  
        <!-- URL input -->
        <div v-if="activeInput === 'url'" class="space-y-4">
          <fwb-input
            v-model="videoUrl"
            label="URL del video"
            placeholder="https://ejemplo.com/video.mp4"
            class="w-full"
          />
          <button
            class="bg-blue-500 text-white px-4 py-2 rounded"
            @click="saveVideoUrl"
            :disabled="!videoUrl"
          >
            Guardar URL
          </button>
        </div>
      </div>
  
      <!-- Video preview -->
      <div v-if="currentVideo" class="mt-6">
        <h3 class="text-lg font-semibold mb-4">Video de la propiedad</h3>
        <div class="bg-white shadow-md rounded-lg overflow-hidden">
            <!-- Si es un video de YouTube -->
            <div v-if="currentVideo.type === 'youtube'">
            <iframe 
                width="100%" 
                height="315" 
                :src="'https://www.youtube.com/embed/' + extractYoutubeId(currentVideo.url)" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
            </div>
            <!-- Si es un video local o en formato mp4/webm -->
            <div v-else>
            <video class="h-full w-full rounded-lg" controls>
                <source :src="currentVideo.url || currentVideo.base64" :type="currentVideo.type" />
                Tu navegador no soporta la etiqueta de video.
            </video>
            </div>
            <div class="p-4 flex justify-between items-center">
            <span class="text-gray-700">{{ currentVideo.name || 'Video actual' }}</span>
            <button
                class="text-red-500 hover:text-red-700"
                @click="deleteVideo"
            >
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                >
                <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="m18 9l-.84 8.398c-.127 1.273-.19 1.909-.48 2.39a2.5 2.5 0 0 1-1.075.973C15.098 21 14.46 21 13.18 21h-2.36c-1.279 0-1.918 0-2.425-.24a2.5 2.5 0 0 1-1.076-.973c-.288-.48-.352-1.116-.48-2.389L6 9m7.5 6.5v-5m-3 5v-5m-6-4h4.615m0 0l.386-2.672c.112-.486.516-.828.98-.828h3.038c.464 0 .867.342.98.828l.386 2.672m-5.77 0h5.77m0 0H19.5"
                />
                </svg>
            </button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from "vue";
  import { FwbInput } from "flowbite-vue";
  import axios from "axios";
  import LoaderTuin from "@/commond/LoaderTuin.vue";
  import ToastAlert from "@/commond/ToastAlert.vue";
  import { useStore } from "vuex";
  
  const store = useStore();
  const session = computed(() => store.getters.getUserSession);
  
  const props = defineProps({
    propertyId: {
      type: Number,
      required: true,
      default: 0,
    },
  });
  
  //const fileInput = ref(null);
  //const dragging = ref(false);
  const activeInput = ref('url');
  const videoUrl = ref('');
  const currentVideo = ref(null);
  const toastAlert = ref(false);
  const typeMsg = ref("");
  const msg = ref("");
  const loader = ref(false);
  
  //const handleDrop = (event) => {
  //  dragging.value = false;
  //  const file = event.dataTransfer.files[0];
  //  if (file && file.type.startsWith('video/')) {
  //    handleFile(file);
  //  } else {
  //    showToast('error', 'Por favor, selecciona un archivo de video válido');
  //  }
  //};
  
  //const handleFileSelect = (event) => {
  //  const file = event.target.files[0];
  //      if (file) {
  //      handleFile(file);
  //      }
  //  };
    
  //  const handleFile = (file) => {
  //      if (file.size > 100 * 1024 * 1024) {
  //      showToast('error', 'El archivo es demasiado grande. Máximo 100MB.');
  //      return;
  //      }
    
  //      const reader = new FileReader();
  //      reader.readAsDataURL(file);
  //      reader.onload = () => {
  //      currentVideo.value = {
  //          name: file.name,
  //          size: file.size,
  //          type: file.type,
  //         base64: reader.result
  //      };
  //      saveVideo();
  //      };
  //  };
    
  //  const triggerFileInput = () => {
  //      fileInput.value.click();
  //  };
    
  /*  const saveVideo = async () => {
        loader.value = true;
        const url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/UploadVideos`;

        const formData = new FormData();
        formData.append("video_type", "file");
        
        // Convertir base64 a Blob (archivo binario)
        const byteCharacters = atob(currentVideo.value.base64.split(',')[1]);  // Obtener la parte después de 'data:video/mp4;base64,'
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset++) {
            byteArrays.push(byteCharacters.charCodeAt(offset));
        }
        const blob = new Blob([new Uint8Array(byteArrays)], { type: currentVideo.value.type });

        // Crear un archivo a partir del Blob
        const file = new File([blob], currentVideo.value.name, { type: currentVideo.value.type });

        formData.append("video_file", file); // Añadir el archivo al FormData

        const options = {
            headers: {
            "Authorization": `Bearer ${session.value.token_api}`,
            },
        };

        try {
            const response = await axios.post(url, formData, options);
            if (response.status === 200) {
            showToast('success', 'Video guardado exitosamente');
            await getVideo();
            }
        } catch (error) {
            showToast('error', 'Error al guardar el video');
            console.error("Error:", error);
        } finally {
            loader.value = false;
        }
    };*/
  
  const saveVideoUrl = async () => {
        if (!videoUrl.value) return;

        loader.value = true;
        const url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/UploadVideos`;

        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${session.value.token_api}`,
            },
        };

        try {
            const payload = {
                video_type: "url", // Especificamos que es una URL
                video_url: videoUrl.value, // Enviamos la URL del video
            };

            const response = await axios.post(url, payload, options);

            if (response.status === 200) {
                showToast('success', 'URL del video guardada exitosamente');
                currentVideo.value = {
                    url: videoUrl.value,
                    type: 'video/mp4',
                    name: 'Video desde URL',
                };
                videoUrl.value = '';
                await getVideo();
            }
        } catch (error) {
            showToast('error', 'Error al guardar la URL del video');
            console.error("Error:", error);
        } finally {
            loader.value = false;
        }
    };
  
    const deleteVideo = async () => {
        const url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/DeleteVideos`;

        const options = {
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.value.token_api}`,
            },
        };

        try {
            const response = await axios.post(url, {}, options); // No necesitamos enviar datos en el cuerpo

            if (response.status === 200) {
            showToast("success", "Video eliminado correctamente.");
            await getVideo();
            // Aquí puedes hacer otras acciones después de la eliminación (como actualizar la vista)
            }
        } catch (error) {
            console.error("Error al eliminar el video:", error);
            showToast("error", "Hubo un problema al eliminar el video.");
        }
        };
  
        const getVideo = async () => {
            const url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/video`;

            const options = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${session.value.token_api}`,
                },
            };

            try {
                const response = await axios.get(url, options);
                
                if (response.status === 200) {
                    if (response.data.video) {
                        const videoUrl = response.data.video.video_url;
                        let videoType = '';
                        
                        // Si el video es de YouTube, asignar el tipo adecuado
                        if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
                            videoType = 'youtube';
                        } else {
                            // Si es un video local, determinamos el tipo según la extensión del archivo
                            videoType = videoUrl.endsWith('.mp4') ? 'video/mp4' : 
                                        videoUrl.endsWith('.webm') ? 'video/webm' : 
                                        'video/mp4'; // Valor por defecto
                        }

                        // Asignar tanto la URL como el tipo al video actual
                        currentVideo.value = {
                            url: videoUrl,
                            type: videoType,
                            name: response.data.video.video_name || 'Video actual',
                        };
                        console.log(currentVideo.value);  // Verifica que la URL y el tipo se asignaron correctamente
                    } else {
                        // Si no hay video, vaciar los datos del video actual
                        currentVideo.value = null;
                        console.log("No video available for this property.");
                    }
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };
  const showToast = (type, message) => {
    toastAlert.value = true;
    typeMsg.value = type;
    msg.value = message;
    setTimeout(() => {
      toastAlert.value = false;
      typeMsg.value = "";
      msg.value = "";
    }, 3000);
  };
  const extractYoutubeId = (url) => {
    const regex = new RegExp("(?:https?:\\/\\/)?(?:www\\.)?(?:youtube\\.com(?:\\/(?:[^\\n\\s\\/]+\\/|\\S+\\/|(?:.*[?&]v=)))|youtu\\.be\\/)([a-zA-Z0-9_-]{11})");
    const match = url.match(regex);
    return match ? match[1] : null;
}
  
  onMounted(async () => {
    loader.value = true;
    await getVideo();
    loader.value = false;
  });
  </script>