
<template>
  <div>
    <div>
      <LoaderTuin :is-loading="loader" />
    </div>
    <div
      v-if="!sloader"
      class="card"
    >      
      <div class="flex">
        <ToastAlert
          :is-active="toastAlert"
          :type="typeMsg"
          :message="msg"
        />
      </div>

      <div class="flex w-full justify-end items-end mt-5 mb-5">
        <fwb-tooltip>
          <template #trigger>
            <fwb-toggle
              v-model="is_available"
              label="Disponible"
              :disabled="!complete_property"
              @change="changeStatus"
            />
          </template>
          <template #content>
            <div v-show="complete_property">
              Activa tu propiedad para que sea visible en Tuin
            </div>
            <div v-show="!complete_property">
              <span v-if="missing_images">Aún te falta agregar imágenes para poder hacer visible tu propiedad</span>
              <span v-else>Aún te faltan algunos datos para poder hacer visible tu propiedad</span>
            </div>
          </template>
        </fwb-tooltip>
      </div>

      <Accordion value="0">
        <AccordionPanel value="0">
          <AccordionHeader
            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
            data-accordion-target="#accordion-collapse-body-1"
            aria-expanded="true"
            aria-controls="accordion-collapse-body-1"
          >
            <button type="button">
              <div class="flex float-left">
                <img
                  v-if="is_complete_gral"
                  src="@/assets/imgs/icons/circle_ok.png"
                  alt="tuin form complete"
                  width="25"
                >

                <img
                  v-if="!is_complete_gral"
                  src="@/assets/imgs/icons/circle_disabled.png"
                  alt="tuin form complete"
                  width="25"
                >
              </div>
              <div class="flex float-left ml-2">
                Información general
              </div>
            </button>
          </AccordionHeader>
          <AccordionContent>
            <div class="border">
              <FormGeneralComponent 
                :resp="data_property.general" 
                :property-id="props.propertyId"
                @trigger-update="retriveProperty"  
              />
            </div>
          </AccordionContent>
        </AccordionPanel>

        <!-- :disabled="!is_complete_location"   -->
        <AccordionPanel 
          value="1"
          :disabled="!is_complete_gral"
        >
          <!-- :class="{ 'bg-gray-300': !is_complete_location, 'border-gray-200': is_complete_location }" -->
          <AccordionHeader
            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
            data-accordion-target="#accordion-open-body-2"
            aria-expanded="false"
            aria-controls="accordion-open-body-2"
          >
            <button type="button">
              <div class="flex float-left">
                <img
                  v-if="is_complete_location"
                  src="@/assets/imgs/icons/circle_ok.png"
                  alt="tuin form complete"
                  width="25"
                >
                <img
                  v-if="!is_complete_location"
                  src="@/assets/imgs/icons/circle_disabled.png"
                  alt="tuin form complete"
                  width="25"
                >
              </div>
              <div class="flex float-left ml-2">
                Ubicación
                <span v-show="!is_complete_gral">
                  (Debe ingresar los datos de información general para continuar )
                </span>
              </div>
            </button>
          </AccordionHeader>
          <AccordionContent>
            <div class="border">
              <FormLocationComponent  
                :resp="data_property" 
                :property-id="property_id"
                @trigger-update="retriveProperty"  
              />
            </div>
          </AccordionContent>
        </AccordionPanel>

        <!-- :disabled="!is_complete_characters"  -->
        <AccordionPanel 
          value="2" 
          :disabled="!is_complete_location"
        >
          <!-- :class="{ 'bg-gray-300': !is_complete_characters, 'border-gray-200': is_complete_characters }" -->
          <AccordionHeader
            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          >
            <button type="button">
              <div class="flex float-left">
                <img
                  v-if="is_complete_characters"
                  src="@/assets/imgs/icons/circle_ok.png"
                  alt="tuin form complete"
                  width="25"
                >

                <img
                  v-if="!is_complete_characters"
                  src="@/assets/imgs/icons/circle_disabled.png"
                  alt="tuin form complete"
                  width="25"
                >
              </div>
              <div class="flex float-left ml-2">
                Características 
                <span 
                  v-show="!is_complete_location"
                  class=" text-sm font-bold text-slate-900 pl-2"
                >
                  (Debe ingresar los datos de ubicación general para continuar)
                </span>
              </div>
            </button>
          </AccordionHeader>
          <AccordionContent>
            <div class="border">
              <FormCharactersComponent  
                :resp="data_property" 
                :property-id="property_id"
                @trigger-update="retriveProperty"  
              />
            </div>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
    </div>
  </div>
</template>

<script setup>
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import ToastAlert from "@/commond/ToastAlert.vue";
import FormGeneralComponent from "./FormGeneralComponent.vue";
import FormLocationComponent from "./FormLocationComponent.vue";
import FormCharactersComponent from "./FormCharactersComponent.vue";
import axios from "axios";
import { FwbToggle, FwbTooltip } from "flowbite-vue";
import LoaderTuin from "@/commond/LoaderTuin.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
let store = useStore();
let session = computed(() => store.getters.getUserSession);

const props = defineProps({
  propertyId: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(['triggerUpdateProperty']);

//const available         = ref(1);
let loader = ref(false);
let is_complete_gral = ref(false);
let is_complete_location = ref(false);
let is_complete_characters = ref(false);
let is_complete_gallery = ref(false);

let data_property = ref({});

//Este botón cambia el estatus de la propiedad para que sea visible en tuin
let is_available = ref(false);

// Este campo valida si ya guardo la info necesaria para activar el botónde activar propiedad

let property_id = ref(0);
// Computed para determinar si faltan imágenes
const missing_images = computed(() => {
    return is_complete_gral.value && is_complete_location.value && is_complete_characters.value && !is_complete_gallery.value;
});
let complete_property = computed(() => {
  return is_complete_gral.value && is_complete_location.value && is_complete_characters.value && is_complete_gallery.value;
});
// Definimos la variable reactiva que controla la visibilidad de la modal.

let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

async function getProperty() {
  loader.value = true;
  let url_cnn = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${property_id.value}`;

  var options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.value.token_api}`,
    },
  };

  try {
    const response = await axios.get(url_cnn, options);
    const resp = response.data.resp;

    // Set validation values
    is_complete_gral.value = resp.fn_validation_gral;
    is_complete_location.value = resp.fn_validation_location;
    is_complete_characters.value = resp.fn_validation_characters;
    is_complete_gallery.value = resp.fn_validation_images;
    
    // Check if property was previously available but now missing images
    const wasAvailable = resp.status && resp.status.status_id === 1;
    if (wasAvailable && !is_complete_gallery.value) {
      // Set is_available to false
      is_available.value = false;
      // Call changeStatus to update the backend
      await changeStatus();
      
      // Hide toast after 3 seconds
      setTimeout(() => {
        toastAlert.value = false;
      }, 3000);
    } else {
      // Normal status setting
      is_available.value = wasAvailable;
    }

    // Set complete property check
    if (is_complete_gral.value && is_complete_location.value && 
        is_complete_characters.value && is_complete_gallery.value) {
      complete_property.value = true;
    }

    // Assign complete property data
    data_property.value = resp;
  } catch (error) {
    console.log("[ERROR]", error.response);
    toastAlert.value = true;
    typeMsg.value = "error";
    msg.value = "Error al cargar la propiedad";
    
    setTimeout(() => {
      toastAlert.value = false;
    }, 3000);
  } finally {
    loader.value = false;
  }
}

async function changeStatus(){
  let url_cnn = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${property_id.value}/change_status`;

  var options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.value.token_api}`,
    },
  };

  // Si is_available es true, se envía 1. Si es false, se envía 2.
  let param_post = { "status": is_available.value ? 1 : 2 };

  axios
    .put(url_cnn, param_post, options)
    .then((response) => {
      var resp = response.data;     
      console.log(resp);      
    })
    .catch((error) => {
      console.log("[ERROR]", error.response);
      loader.value = false;
    });
}

onMounted(async () => {
  if (props.propertyId != null) {
    property_id.value = props.propertyId;
    await getProperty();
  }
});


async function retriveProperty(propertyId){
  console.log('[PROPERTY_ID_TRIGGER]',propertyId)
  emit('triggerUpdateProperty', propertyId  );
  property_id.value = propertyId;
  await getProperty();
}
</script>

