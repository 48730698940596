<template>
    <div v-if="property.video_url" class="mt-4">
        <fwb-heading tag="h3">
            Video de la propiedad

        </fwb-heading>
      <div class="bg-white shadow-md rounded-lg overflow-hidden mt-4">
        <!-- Video local -->
        <video
          v-if="property.video_type === 'file'"
          class="w-full h-64 object-cover"
          :src="property.video_url"
          controls
        >
          Tu navegador no soporta la reproducción de videos.
        </video>
  
        <!-- Video URL incrustado -->
        <iframe
          v-else-if="property.video_type === 'url'"
          class="w-full h-64"
          :src="embedVideoUrl"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
  
        <div class="p-4">
          <h3 class="text-lg font-bold mb-2">{{ property.property_name }}</h3>
          <!-- Video URL incrustado 
          <p class="text-sm text-gray-600 mb-4">
            {{ property.address }}, {{ property.municipality }}, {{ property.estado.estado }}
          </p>
          <div class="flex items-center justify-between">
            <div>
              <p class="text-sm text-gray-500">Precio:</p>
              <p class="text-lg font-semibold text-tuin_base-500">
                {{ property.property_value }}
              </p>
            </div>
            <div>
              <p class="text-sm text-gray-500">Metros Construidos:</p>
              <p class="text-lg font-semibold">
                {{ property.constructed_metres }} m²
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue'; // Importar correctamente 'computed'
  import { FwbHeading } from "flowbite-vue";
  const props = defineProps({
    property: {
      type: Object,
      required: true,
    },
  });
  
  // Convertir URLs a enlaces de incrustación válidos
  const embedVideoUrl = computed(() => {
    if (props.property.video_type === 'url') {
      
  
      // Si no es YouTube, devolver la URL original
      return props.property.video_url;
    }
  
    return null;
  });
  </script>
  
  <style scoped>
  /* Personaliza los colores de Tuin si es necesario */
  .text-tuin_base-500 {
    color: #4CAF50; /* Ejemplo de color base */
  }
  </style>
  