<template class="py-0">
  <fwb-sidebar class="bg-black text-white pt-10 py-0">
    <div
      class="bg-black text-white p-0 m-0 h-screen fixed w-[15%] ml-[-12px] mt-[-20px] mr-[-12px] px-0"
    >
      <fwb-sidebar-logo
        name="Asesores"
        :logo="logoTuin"
        tag="router-link"
        class="bg-black text-white"
      />
      <!-- --- {{ step_user && step_user.step==3 ?step_user.step :0   }} -->
      <!-- Dashboard 
      <fwb-sidebar-item
        to="/dashboard"
        class="p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-black"
        :class="{ 'bg-tuin-100': current_path == 'dashboard' }"
        @click="change_path('dashboard')"
      >
        <template #icon>        
          <svg
            class="text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 22 21"
            width="25"
          >
            <path
              d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"
            />
            <path
              d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"
            />
          </svg>
        </template>
        <template #default>
          Dashboard  
        </template>
      </fwb-sidebar-item>
       Dashboard -->

      <!-- Bolsa inmobiliaria 
        v-if="step_user && step_user.step ?step_user.step :0 " -->
      <fwb-sidebar-item
        v-if="step_user && step_user.step >=3 "
        to="/bolsa-inmobiliaria"
        class="m-2 p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"        
      >
        <template #icon>
          <img
            src="@/assets/imgs/icons/bolsa_inmobiliadia.svg"
            alt="tuin bolsa inmobiliaria"
            width="30"
          >          
        </template>
        <template #default>
          Bolsa inmobiliaria  
        </template>
      </fwb-sidebar-item>
      <!-- Dashboard -->

      
      <!-- Propiedades -->
      <fwb-sidebar-item
        v-if="step_user && step_user.step >= 3 "
        to="/properties"
        class="m-2 p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"
        :class="{ 'bg-tuin-100': current_path == 'properties' }"
        @click="change_path('properties')"
      >
        <template #icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="text-white text-xs"
            width="25"
            height="25"
            viewBox="0 0 2048 2048"
          >
            <path
              fill="white"
              d="M1280 1920h384v128h-512v-896H768v896H256V987l-83 82l-90-90l877-877l877 877l-90 90l-83-82v165h-128V859L960 282L384 859v1061h256v-896h640zm768-640v768h-128v-256h-512v-512zm-128 128h-384v256h384z"
            />
          </svg>
        </template>
        <template #default>
          <span class="text-white">Mis Propiedades </span>
        </template>
      </fwb-sidebar-item>
      <!-- Propiedades -->

      <!-- Prospectos -->
      <fwb-sidebar-item 
        v-if="step_user && step_user.step >= 3 "
        to="/customers"
        class="m-2 p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"
        :class="{ 'bg-tuin-100': current_path == 'customers' }"
        @click="change_path('prospect')"
      >
        <template #icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="text-white"
            width="25"
            height="25"
            viewBox="0 0 32 32"
          >
            <path
              fill="white"
              d="M28.523 23.813c-.518-.51-6.795-2.938-7.934-3.396c-1.133-.45-1.585-1.697-1.585-1.697s-.51.282-.51-.51c0-.793.51.51 1.02-2.548c0 0 1.415-.397 1.134-3.68h-.34s.85-3.51 0-4.698c-.853-1.188-1.187-1.98-3.06-2.548c-1.87-.567-1.19-.454-2.548-.396c-1.36.057-2.492.793-2.492 1.188c0 0-.85.057-1.188.397c-.34.34-.906 1.924-.906 2.32s.283 3.06.566 3.624l-.337.11c-.283 3.284 1.132 3.682 1.132 3.682c.51 3.058 1.02 1.755 1.02 2.548c0 .792-.51.51-.51.51s-.453 1.246-1.585 1.697c-1.132.453-7.416 2.887-7.927 3.396c-.51.52-.453 2.896-.453 2.896h12.036l.878-3.46l-.78-.78l1.343-1.345l1.343 1.344l-.78.78l.878 3.46h12.036s.063-2.378-.453-2.897z"
            />
          </svg>
        </template>
        <template #default>
          <span
            class="p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"
          >
            Prospectos
          </span>
        </template>
      </fwb-sidebar-item>
      <!-- Prospectos -->

      <!-- Configuración  -->
      <div>
        <fwb-sidebar-dropdown-item         
          id="dorpdown_menu"
          ref="dropdownRef"
          class="m-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:text-black dark:hover:bg-gray-700 "                 
          :class="{ 'hidden': step_user && step_user.step < 3 }"
        >
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-white hover:text-black dark:hover:bg-gray-700"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <mask id="lineMdCogFilled0">
                <defs>
                  <symbol id="lineMdCogFilled1">
                    <path
                      d="M11 13L15.74 5.5C16.03 5.67 16.31 5.85 16.57 6.05C16.57 6.05 16.57 6.05 16.57 6.05C16.64 6.1 16.71 6.16 16.77 6.22C18.14 7.34 19.09 8.94 19.4 10.75C19.41 10.84 19.42 10.92 19.43 11C19.43 11 19.43 11 19.43 11C19.48 11.33 19.5 11.66 19.5 12z"
                    >
                      <animate
                        fill="freeze"
                        attributeName="d"
                        begin="0.5s"
                        dur="0.2s"
                        values="M11 13L15.74 5.5C16.03 5.67 16.31 5.85 16.57 6.05C16.57 6.05 16.57 6.05 16.57 6.05C16.64 6.1 16.71 6.16 16.77 6.22C18.14 7.34 19.09 8.94 19.4 10.75C19.41 10.84 19.42 10.92 19.43 11C19.43 11 19.43 11 19.43 11C19.48 11.33 19.5 11.66 19.5 12z;M11 13L15.74 5.5C16.03 5.67 16.31 5.85 16.57 6.05C16.57 6.05 19.09 5.04 19.09 5.04C19.25 4.98 19.52 5.01 19.6 5.17C19.6 5.17 21.67 8.75 21.67 8.75C21.77 8.92 21.73 9.2 21.6 9.32C21.6 9.32 19.43 11 19.43 11C19.48 11.33 19.5 11.66 19.5 12z"
                      />
                    </path>
                  </symbol>
                </defs>
                <g 
                  fill="none" 
                  stroke="#fff" 
                  stroke-width="2"
                >
                  <path
                    stroke-dasharray="36"
                    stroke-dashoffset="36"
                    stroke-width="5"
                    d="M12 7c2.76 0 5 2.24 5 5c0 2.76 -2.24 5 -5 5c-2.76 0 -5 -2.24 -5 -5c0 -2.76 2.24 -5 5 -5Z"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      dur="0.5s"
                      values="36;0"
                    />
                    <set
                      fill="freeze"
                      attributeName="opacity"
                      begin="0.5s"
                      to="0"
                    />
                  </path>
                  <g 
                    fill="#fff"
                    stroke="none"
                    opacity="0"
                  >
                    <use href="#lineMdCogFilled1" />
                    <use
                      href="#lineMdCogFilled1"
                      transform="rotate(60 12 12)"
                    />
                    <use
                      href="#lineMdCogFilled1"
                      transform="rotate(120 12 12)"
                    />
                    <use
                      href="#lineMdCogFilled1"
                      transform="rotate(180 12 12)"
                    />
                    <use
                      href="#lineMdCogFilled1"
                      transform="rotate(240 12 12)"
                    />
                    <use
                      href="#lineMdCogFilled1"
                      transform="rotate(300 12 12)"
                    />
                    <set
                      fill="freeze"
                      attributeName="opacity"
                      begin="0.5s"
                      to="1"
                    />
                  </g>
                </g>
                <circle
                  cx="12"
                  cy="12"
                  r="3.5"
                />
              </mask>
              <rect
                width="24"
                height="24"
                fill="white"
                mask="url(#lineMdCogFilled0)"
              />
            </svg>
          </template>
          <template #trigger>
            <span 
              class="text-white hover:text-black dark:hover:bg-gray-700"
            >Configuración
              
            </span>
          </template>

          <template #default>
            <fwb-sidebar-item
              to="/info-profile"
              class="m-2 p-2 pl-4 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"
              :class="{ 'bg-tuin-100': current_path == 'info-profile' }"
              @click="change_path('info-profile')"            
            >
              Mi perfil
            </fwb-sidebar-item>
            <fwb-sidebar-item
              to="/info-company"
              class="m-2 p-2 pl-4 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"
              :class="{ 'bg-tuin-100': current_path == 'info-company' }"
              @click="change_path('info-company')"
            >
              Inmobiliaria
            </fwb-sidebar-item>
            <fwb-sidebar-item
              to="/change-password"
              class="m-2 p-2 pl-4 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"
              :class="{ 'bg-tuin-100': current_path == 'change-password' }"
              @click="change_path('change-password')"
            >
              Contraseña
            </fwb-sidebar-item>
            <fwb-sidebar-item
              to="/info-networks"
              class="m-2 p-2 pl-4 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"
              :class="{ 'bg-tuin-100': current_path == 'info-networks' }"
              @click="change_path('info-networks')"
            >
              Redes sociales
            </fwb-sidebar-item>          
          </template>
        </fwb-sidebar-dropdown-item>
      </div>
      <!-- Configuración  -->

      <!-- Salir  -->
      <fwb-sidebar-item 
        class="m-2 p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-tuin-100 dark:text-black dark:hover:bg-gray-700"
        :class="{ 'bg-tuin-100': current_path.value == 'prospect' }"
        @click="outsession"
      >
        <template #icon>
          <svg
            class="text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="25"
            height="25"
            viewBox="0 0 18 16"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
            />
          </svg>
        </template>
        <template #default>
          Salir
        </template>
      </fwb-sidebar-item>
      <!-- Salir  -->
    </div>
  </fwb-sidebar>
</template>
<script setup>
import {
  FwbSidebar,
  FwbSidebarItem,
  FwbSidebarLogo,
  FwbSidebarDropdownItem,
} from "flowbite-vue";
import logoTuin from "@/assets/imgs/LogoTuin.svg";
import { ref, onMounted,  computed } from "vue";

// import { useRouter } from "vue-router";
// let router = useRouter();

import { useStore } from 'vuex';

let store = useStore();

const step_user = computed(() => store.getters.getUserSession);

//let session = computed(() => store.getters.getUserSession);
//let step_user = ref(0);
let current_path  = ref("configuration");
const dropdownRef = ref(null);


async function outsession() {
  await store.dispatch("clearUserSession"); // Limpia la sesión
  window.location.replace("/login");
}

function change_path(path_link) {
  current_path.value  = path_link;
  //router.push({ name: path_link });
}

const openDropdown = () => {
  const dropdownTrigger = dropdownRef.value.$el.querySelector('[aria-controls="dropdown-content"]');
  if (dropdownTrigger) {
    dropdownTrigger.click(); // Simula el clic para abrir el dropdown    
  }
};

const setActivePath = () => {
  
  openDropdown();
  //const path = window.location.pathname;
  //let sub_menu  = document.getElementById('dorpdown_menu');
  // if(step_user.value.step >= 3){
  //   switch (path) {
  //     case '/dashboard':
  //       current_path.value = 'dashboard';
  //       break;
  //     case '/properties':
  //       current_path.value = 'properties';
  //       break;
  //     case '/prospect':
  //       current_path.value = 'prospect';
  //       break;
  //     case '/info-profile':
  //       current_path.value = 'info-profile';
  //       openDropdown();
  //       break;
  //     case '/info-company':
  //       current_path.value = 'info-company';
  //       openDropdown();
  //       break;
  //     case '/info-password':
  //       current_path.value = 'info-password';
  //       openDropdown();
  //       break;
  //     case '/info-networks':
  //       current_path.value = 'info-networks';
  //       openDropdown();
  //       break;
  //     default:
  //       current_path.value = '';
  //       break;
  //   }
  // }
  

};

// Llama a setActivePath al montar el componente

onMounted(() => {  
  setActivePath();    
});

// Observamos los cambios en `session`
// watch(
//   () => store.getters.getUserStep,
//   (newStep) => {
//     console.log('datossss', newStep)
//     // step_user.value = newStep;
//     console.log('El valor de step ha cambiado a:', newStep);
//     // Aquí puedes ejecutar lógica personalizada si necesitas responder a los cambios en tiempo real
//   }
// );

</script>

<style scoped>
aside > div:first-child {
  background-color: black !important;
  color: white;
}

.open {
  /* Aquí puedes agregar clases adicionales que controlen la visibilidad */
  display: block;
}
</style>