<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="h-mx-auto sm:h-screen">
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />

    <div
      v-if="loader_page"
      class="fixed inset-0 flex items-center justify-center bg-black z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      >
    </div>

    <!-- Mapa del sitio  -->
    <div class="flex flex-wrap">
      <div class="flex flex-1">
        <fwb-heading tag="h2">
          Redes sociales
        </fwb-heading>
      </div>
      <div class="flex flex-1 justify-end items-end">
        <fwb-breadcrumb>
          <fwb-breadcrumb-item href="/change-password">
            Redes sociales
          </fwb-breadcrumb-item>
        </fwb-breadcrumb>
      </div>
    </div>
    <!-- Mapa del sitio  -->

    <div
      v-if="!loader_page"
      class="w-full rounded overflow-hidden shadow-lg bg-white mt-4 p-4"
    >
      <div class="flex">
        <div class="text-center sm:text-left">
          Para continuar, por favor complete los siguientes campos con su
          información
        </div>
      </div>

      <div class="flex flex-col sm:flex-row mt-4 sm:mt-10">
        <div class="w-full sm:w-1/2 mb-10">
          <div class="flex justify-center items-center text-2xl font-bold">
            Redes sociales de asesor
          </div>
          <!-- Contenido del primer div -->
          <div
            v-for="(item, index) in networks_broker"
            :key="item.id"
            class="flex mt-5"
          >
            <div
              class="w-[20%] sm:w-[15%] p-2 flex justify-center items-center"
            >
              <div v-html="item.icon" />
            </div>

            <div class="w-[80%] sm:w-[75%] p-2">
              <fwb-input
                v-model="networks_broker[index].link_broker"
                :placeholder="getPlaceholder(index)"
                size="sm"
                class="w-full"
                @input="validateLinkWithRegex(item.link_broker, index)"
                :class="{'border-red-500': item.error}"
              />
              <span v-if="item.error" class="text-red-500 text-sm">{{ item.error }}</span>
            </div>
          </div>

          <div class="flex mt-5 justify-center items-center">
            <fwb-button
              class="bg-tuin-100 hover:bg-tuin-100"
              size="xl"
              @click="saveNetworksBroker()"
            >
              Guardar redes de asesor
            </fwb-button>
          </div>
        </div>

        <div class="w-full sm:w-1/2 mb-5">
          <!-- Contenido del segundo div -->
          <div class="flex justify-center items-center text-2xl font-bold">
            Redes sociales de la compañía
          </div>
          <!-- Contenido del primer div -->
          <div
            v-for="(item, index) in networks_company"
            :key="item.id"
            class="flex mt-5"
          >
            <div
              class="w-[20%] sm:w-[15%] p-2 flex justify-center items-center"
            >
              <div v-html="item.icon" />
            </div>

            <div class="w-[80%] sm:w-[75%] p-2">
              <fwb-input
                v-model="networks_company[index].link_company"
                :placeholder="getPlaceholder(index)"
                size="sm"
                class="w-full"
                @input="validateLinkWithRegex(item.link_company, index)"
                :class="{'border-red-500': item.error}"
              />
              <span v-if="item.error" class="text-red-500 text-sm">{{ item.error }}</span>
            </div>
          </div>

          <div class="flex mt-5 justify-center items-center">
            <fwb-button
              class="bg-tuin-100 hover:bg-tuin-100"
              size="xl"
              @click="saveNetworksCompany()"
            >
              Guardar redes de compañía
            </fwb-button>
          </div>
        </div>
      </div>

      <div
        v-if="step < 3"
        class="flex flex-col sm:flex-row mt-4 sm:mt-10 justify-end items-end"
      >
        <fwb-button
          color="alternative"
          size="xl"
          @click="saveLater()"
        >
          Agregar más tarde
        </fwb-button>
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { computed, ref, onMounted } from "vue";
import ToastAlert from "@/commond/ToastAlert.vue";
// LLamar a la sesion
import { useStore } from "vuex";
import axios from "axios";
import {
  FwbInput,
  FwbButton,
  FwbBreadcrumb,
  FwbBreadcrumbItem,
  FwbHeading,
} from "flowbite-vue";
// import { useRouter } from "vue-router";
// let router = useRouter();

let networks_broker = ref([]);
let networks_company = ref([]);
let loader_page = ref(false);

let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

const store = useStore();
let session = computed(() => store.getters.getUserSession);
let step = ref(0);
//let facebook = ref('');

const placeholders = [
  "https://www.facebook.com/user",
  "https://x.com/user",
  "https://www.linkedin.com/in/user",
  "https://www.tiktok.com/@user",
  "https://www.youtube.com/@user",
  "https://www.instagram.com/user",
];

const socialMediaRegex = {
  facebook: /^https:\/\/(www\.)?facebook\.com\/(?:[a-zA-Z0-9_.-]+|profile\.php\?id=\d+)$/,
  x: /^https:\/\/(www\.)?x\.com\/[a-zA-Z0-9_]+$/,
  linkedin: /^https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+$/,
  tiktok: /^https:\/\/(www\.)?tiktok\.com\/@[\w-]+$/,
  youtube: /^https:\/\/(www\.)?youtube\.com\/(?:@[a-zA-Z0-9_-]+|(c|channel|user)\/[a-zA-Z0-9_-]+)$/,
  instagram: /^https:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_.]+$/,
};

onMounted(async () => {
  try {
    console.log(session.value.step)
    step.value = session.value.step;
    loader_page.value = true;
    getNetworks();
  } catch (error) {
    console.error("Error al intentar traer los datos", error);
  }
});

async function getNetworks() {
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/broker/profile/networks/${session.value.id}`,
      options
    );

    if (response.status == 401) {
      store.dispatch("clearUserSession");
      loader_page.value = false;
    }

    if (response.status == 200) {
      networks_broker.value = JSON.parse(JSON.stringify(response.data.data));
      networks_company.value = JSON.parse(JSON.stringify(response.data.data));
      // filteredProperties.value = response.data.resp;
      loader_page.value = false;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

async function saveNetworksBroker() {
  loader_page.value = true;
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    let params_item = [];

    this.networks_broker.forEach((item, index) => {
      if (item.link_broker && !validateLinkWithRegex(item.link_broker, index)) {
        item.error = `El link no es válido para la plataforma correspondiente`;
        loader_page.value = false;
        throw new Error(`Link inválido: ${item.link_broker}`);
      } else {
        item.error = null;
      }

      let row = {};
      row["id"] = item.id;
      row["link_broker"] = item.link_broker;
      row["user_id"] = session.value.id;
      row["company_id"] = session.value.company_id;

      params_item.push(row);
    });

    let params = {
      type: "broker",
      step: 3,
      networks: params_item,
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/update-networks`,
      params,
      options
    );

    if (response.status == 200) {
      if (session.value.step < 3) {
        store.dispatch("updateStep", 3);
        window.location.href = "/properties";
      }

      showToast("success", "Redes sociales guardadas correctamente");
      loader_page.value = false;
    }
  } catch (error) {
    loader_page.value = false;
    console.error("Error fetching data:", error);
  }
}

async function saveNetworksCompany() {
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    let params_item = [];

    this.networks_company.forEach((item, index) => {
      if (item.link_company && !validateLinkWithRegex(item.link_company, index)) {
        item.error = `El link no es válido para la plataforma correspondiente`;
        throw new Error(`Link inválido: ${item.link_company}`);
      } else {
        item.error = null;
      }

      let row = {};
      row["id"] = item.id;
      row["link_company"] = item.link_company;
      row["user_id"] = session.value.id;
      row["company_id"] = session.value.company_id;

      params_item.push(row);
    });

    let params = {
      type: "company",
      step: 3,
      networks: params_item,
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/update-networks`,
      params,
      options
    );

    if (response.status == 200) {
      store.dispatch("updateStep", 3);
      if (session.value.step < 3) {
        window.location.href = "/properties";
      }
      showToast("success", "Redes sociales guardadas correctamente");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

async function saveLater() {
  loader_page.value = true;
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    let params_item = [];

    let params = {
      type: "broker",
      step: 3,
      networks: params_item,
    };

    console.log("Datos enviados:", params);

    const response = await axios.put(
      `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/update-networks`,
      params,
      options
    );

    if (response.status === 200) {
      console.log("✅ Respuesta API:", response.data);

      // 🔹 Primero, actualiza el step en Vuex
      await store.dispatch("updateStep", 3);

      // 🔹 Luego, carga la sesión actualizada desde Vuex
      const updatedSession = store.getters.getUserSession;

      console.log("✅ Sesión actualizada en Vuex:", updatedSession);

      // 🔹 Asegura que la sesión en localStorage es correcta
      localStorage.setItem("userSession", JSON.stringify(updatedSession));

      loader_page.value = false;

      // 🔹 Redirige cuando todo está actualizado
      window.location.href = "/properties";
    }
  } catch (error) {
    loader_page.value = false;
    console.error("❌ Error en saveLater:", error);
  }
}


async function showToast(type = null, message = null) {
  toastAlert.value = true;
  typeMsg.value = type;
  msg.value = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

function validateLinkWithRegex(link, index) {
  if (!link) return true; // Si el link está vacío, es válido
  
  const keys = Object.keys(socialMediaRegex);
  const platform = keys[index];

  if (!platform) {
    return false; // Índice fuera de rango
  }

  const regex = socialMediaRegex[platform];
  return regex.test(link);
}

function getPlaceholder(index) {
  return placeholders[index];
}
</script>
