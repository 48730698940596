<template>
    <div class="container h-mx-auto sm:h-screen mt-10">
      <ToastAlert
        :is-active="toastAlert"
        :type="typeMsg"
        :message="msg"
      />
      <!-- LOADER  -->
      <div
        v-if="loader_company"
        class="fixed inset-0 flex items-center justify-center bg-black z-50"
      >
        <img
          src="@/assets/imgs/tuin_loader.gif"
          alt="cargando..."
          class="w-[500px]"
        >
      </div>
      <!-- LOADER  -->
      <div class="flex justify-between items-center">
        <div class="text-center sm:text-left">
          Para continuar, por favor complete los siguientes campos con su
          información
        </div>
        <div v-if="step !== 0"> 
          <ShareButton 
            :this-url="slug_url"
            :this-name="inputs.name.value"
            :company-id="id"
            :agent-id="id"
            Type="company" 
          />
        </div>
      </div>
  
      <p v-if="step !== 0">
        Puedes ver los detalles de tu inmobiliaria haciendo clic en el enlace 
        <a 
          :href="`${apiBaseUrl}/inmobiliarias/${slug}`" 
          class="text-pink-500 underline"
          target="_blank" 
          rel="noopener noreferrer">
          {{ "Ver perfil de la inmobiliaria." }}
        </a>
      </p>
      <div class="flex flex-col sm:flex-row mt-4 sm:mt-10">
        <!-- Logo empresa -->
        <div class="w-full sm:w-2/6 mb-4 sm:mb-0">
          <!-- Contenedor principal del logo -->
          <div v-if="!imagePreview">
            <div
              :class="[
                'border-4 border-dashed p-6 rounded-md cursor-pointer relative', // Añadimos 'relative' aquí
                dragging
                  ? 'border-blue-500 bg-blue-100'
                  : 'border-gray-300 bg-gray-50',
              ]"
              class="flex flex-col items-center justify-center space-y-4 p-4 m-4"
              @dragover.prevent
              @dragenter.prevent="dragging = true"
              @dragleave="dragging = false"
              @drop.prevent="handleDrop"
              @click="triggerFileInput"
            >
              <!-- Contenedor de la imagen con posicionamiento relativo -->
              <div class="relative w-full flex justify-center items-center">
                <img
                  id="logo_company"
                  :src="img_src"
                  alt="Logo de copañía"
                  class="max-w-full h-auto"
                >
                <!-- Botón para eliminar -->
                <button 
                  v-if="img_src && img_src !== require('@/assets/imgs/inmobi_default.png')"
                  @click.stop="removeImage"
                  class="absolute -top-2 -right-2 bg-white text-red-500 rounded-full w-6 h-6 flex items-center justify-center shadow-md hover:bg-red-500 hover:text-white transition duration-300 z-10"
                  title="Eliminar imagen"
                >
                  ×
                </button>
              </div>
  
              <p
                v-if="!is_new"
                class="text-gray-500 justify-center items-center"
              >
                Arrastra y suelta archivos aquí tu logo o haz clic para seleccionarlo
              </p>
              <p
                v-if="is_new"
                class="text-gray-500 justify-center items-center"
              >
                Haz clic sobre la imagen si deseas cambiar el logo
              </p>
              <!-- <ul v-else class="list-disc">
            <li v-for="(file, index) in files" :key="index">
              {{ file.name }}
            </li>
          </ul> -->
          
              <input
                ref="fileInput"
                type="file"
                class="hidden"
                accept="image/*"
                @change="handleFileSelect"
              >
              <button
                class="bg-blue-500 text-white px-4 py-2 rounded hidden"
                @click="triggerFileInput"
              >
                Seleccionar Archivos
              </button>
            </div>
          </div>
          <!-- Preview de la imagen si ya fue cargada -->
          <!-- <div v-if="imagePreview">
            <div class="flex flex-col items-center space-y-4">
              <img
                :src="imagePreview"
                alt="Preview de la imagen"
                class="w-64 h-64 object-cover rounded-md"
              />
              <button
                class="bg-red-500 text-white px-4 py-2 rounded"
                @click="clearImage"
              >
                Cambiar Imagen
              </button>
            </div>
          </div> -->
        </div>
        <!-- Fin Logo empresa -->
        <div class="w-full sm:w-4/6">
          <!-- Contenido del segundo div -->
          <!-- <FormDetailComponent @retriveLogo="retriveLogo" /> -->
          <!-- Inicio form  -->
          <div>
            <div class="flex">
              <div class="w-full sm:w-1/2 p-2">
                <fwb-input
                  v-model="inputs.name.value"
                  label="Nombre"
                  placeholder="ej. Tuin "
                  size="sm"
                  class="w-full"
                />
              </div>
  
              <div class="w-full sm:w-1/2 p-2">
                <fwb-input
                  v-model="inputs.email.value"
                  label="Correo"
                  placeholder="ej. hola@tuin.ai"
                  size="sm"
                  class="w-full"
                />
              </div>
            </div>
  
            <div class="flex">
              <div class="w-1/2 p-2">
                <div class="flex-auto">
                  <label
                    for="phone"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Teléfono</label>
  
                  <InputMask
                    id="phone"
                    v-model="inputs.phone.value"
                    mask="99-9999-9999"
                    placeholder="99-9999-9999"
                    class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2 text-sm"
                    fluid
                  />
                </div>
              </div>
  
              <div class="w-1/2 p-2">
                <fwb-input
                  v-model="inputs.website.value"
                  label="Sitio web"
                  placeholder="ej. https://tuin.ai"
                  size="sm"
                  class="w-full"
                />
              </div>
            </div>
  
            <div class="p-2 flex flex-col-1">
              <div class="flex-auto">
                <label
                  for="phone"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Descripción de la inmobiliaria
                </label>
                <ckeditor
                  v-model="description"
                  :editor="editor"
                  :config="editorConfig"
                  class="custom-editor"
                />
              </div>
            </div>
            <div class="flex justify-end items-end">
              <fwb-button
                class="bg-tuin-100 hover:bg-tuin-100"
                size="xl"
                @click="SaveCompany()"
              >
                Guardar
              </fwb-button>
            </div>
          </div>
          <!-- Fin form  -->
        </div>
      </div>
    </div>
  </template>
  <script setup>
  import ToastAlert from "@/commond/ToastAlert.vue";
  import { ref, reactive, watch, computed, onBeforeMount } from "vue";
  import { useStore } from "vuex";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import { FwbInput, FwbButton } from "flowbite-vue";
  import InputMask from "primevue/inputmask";
  import axios from 'axios';
  // import LogoCompanyComponent from "./company/LogoCompanyComponent.vue";
  //import FormDetailComponent from "./company/FormDetailComponent.vue";
  const store = useStore();
  let session = computed(() => store.getters.getUserSession);
  //let imageSrc = computed(() => store.getters.imgSrc);
  let img_src = ref(require('@/assets/imgs/inmobi_default.png'));
  
  import { useRouter } from "vue-router";
  import ShareButton from "./ShareButton.vue";
  let router = useRouter();
  let toastAlert  = ref(false);
  let typeMsg     = ref("");
  let msg         = ref("");
  let step = ref(0);
  const apiBaseUrl = computed(() => process.env.VUE_APP_API_BASE_URL.replace(/\/api$/, ""));
  let inputs = {
      agent_id:     ref(""),
      name:         ref(""),    
      email:        ref(""),
      phone:        ref(""),
      website:      ref(""),
      img_src:      ref(""),
  };
  let slug = ref();
  let slug_url = ref();
  let id = ref();
  let description     = ref("");
 
  
  let loader_company = ref(false);
  
  const editor            = ref(ClassicEditor);
  const editorConfig = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",        
      "blockQuote",
      "|",
      "undo",
      "redo",
      "|",
      "numberedList",
      "bulletedList",
      "|",
      "alignment",
      "indent",
      "outdent",
      "|",
      
      "insertHR",
      "code",
      "codeBlock",
      "removeFormat",
    ],
      height: "500px",
  };
  const dragging = ref(false);
  const files = reactive([]);
  //require('@/assets/imgs/inmobi_default.png')
  //const img_src = ref('');
  
  const is_new = ref(false);
  
  const fileInput = ref(null);
  
  const handleDrop = (event) => {
    dragging.value = false;
    const droppedFiles = event.dataTransfer.files;
    handleFiles(droppedFiles);
  };
  
  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    handleFiles(selectedFiles);
  };
  
  const handleFiles = (fileList) => {
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
      convertToBase64(fileList[i]);
    }
  };
  
  const triggerFileInput = () => {
    fileInput.value.click(); // Aquí es donde se corrige la referencia
  };
  const removeImage = () => {
    img_src.value = require('@/assets/imgs/inmobi_default.png');
    is_new.value = false;
    if (fileInput.value) {
      fileInput.value.value = ''; // Reset file input
    }
  };
  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file); // Lee el archivo como DataURL (base64)
    reader.onload = () => {
      img_src.value = reader.result; // Asigna el base64 al `src` de la imagen
      is_new.value = true;
      //store.dispatch("setImageSrc", reader.result);
      // Aquí puedes enviar el `reader.result` al backend si lo deseas
      //sendToBackend(reader.result);
    };
    reader.onerror = (error) => {
      console.error("Error al convertir a base64: ", error);
    };
  };
  
  // function retriveLogo(logo_api) {
  //   console.log('[LOGO_WATCH]', logo_api)
  //   img_src.value = logo_api
  //   setTimeout(()=>{
  //     loader_company.value = false;
  //   }, 800)
  // }
  async function SaveCompany(){
      //console.log('enviar guardar ', imageSrc.value)
      let telephone = inputs.phone.value;
      let numeroEntero = telephone.replace(/-/g, '');
  
      let stepValue = slug.value ? 3 : 2;
      //colony:             inputs.colony.value,
  
      let params = {
        agent_id:           session.value.id,
        name:               inputs.name.value,
        email:              inputs.email.value,
        phone:              numeroEntero,
        website:            inputs.website.value,
        step:               stepValue,
        img_src:            img_src.value.split('?')[0],
        description:          description.value
      };
      
      // img_src:            imageSrc.value
      //console.log('ImagenSrc', imageSrc.value)    
      let is_valid = await ValidateForm();
  
      let link_post = `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/actualizar_company`;
      console.log(params)
      if(is_valid.status !== 'error' ){
        let options = {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${session.value.token_api}`
          }
        }
        
        const response = await axios.post(link_post, params, options);
  
        if (response.status == 200) {
          showToast('success', 'Datos guardados correctamente')        
          if(session.value.step < 3 ){
            session.value.step=2;
            //router.push({ name: "info_networks" });
            router.push({ name: "info_profile" });
          }
        }
  
      }else{
        toastAlert.value  = true;
        typeMsg.value     = "danger";
        msg.value         = is_valid.message;
        setTimeout(()=>{
          closeToast();
        }, 2000);
      } 
  
  }
  
  async function ValidateForm() {
    // Validar campos obligatorios
    if (inputs.name.value === '') {
      return { status: 'error', message: 'Debes agregar tu nombre' };
    }
  
    if (inputs.email.value === '') {
      return { status: 'error', message: 'Debes agregar tu correo' };
    } else if (!isValidEmail(inputs.email.value)) {
      return { status: 'error', message: 'El formato del correo no es válido' };
    }
  
    if (inputs.phone.value === '') {
      return { status: 'error', message: 'Debes agregar tu teléfono' };
    }
  
    if (inputs.website.value && !isValidURL(inputs.website.value)) {
      return { status: 'error', message: 'El formato del sitio web no es válido' };
    }
  
    return { status: 'success', message: 'Datos Correctos' };
  }
  
  // Función para validar correos electrónicos
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  // Función para validar URLs
  function isValidURL(url) {
    const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-.]*)*\/?$/;
    return urlRegex.test(url);
  }
  
  
  async function getInfoCompany(){
    
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };
  
    let link_api = `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/company`;
    const response = await axios.get(
        link_api,
        options
      );
    try {
      if (response.status == 401) {
        store.dispatch("clearUserSession");
        loader_company.value = false;
      }
  
      if (response.status == 200) {
        console.log(response)
        let info = response.data.resp.info;
        
        inputs.agent_id.value       =  session.value.id;
        inputs.name.value          =  info.name;
        inputs.email.value       =  info.email;
        inputs.phone.value          =  info.phone;
        inputs.website.value        =  info.website;
        description.value = info.description || '';
        slug.value = info.slug;
        slug_url.value = info.slug_url;
        id.value = info.id;
        step.value = 2;
        
        console.log('[logo]', info.logo)
        if (info.logo) {
          img_src.value = `${info.logo}?v=${Date.now()}`;
        }
        // filteredProperties.value = response.data.resp;
        loader_company.value = false;
      }
    } catch (error) {
      loader_company.value = false;
      console.error("Error fetching data:", error);
    }
  }
  
  async function showToast( type = null, message = null){
    toastAlert.value  = true;
    typeMsg.value     = type;
    msg.value         = message;
  
    setTimeout(() => {
      closeToast();
    }, 3000);
  }
  
  async function closeToast(){
    toastAlert.value = false;
    typeMsg.value    = ''
    msg.value        = '' 
  }
  
  onBeforeMount(async() => {
    loader_company.value = true;
    await getInfoCompany();
  });
  
  watch(
    img_src, // Observa directamente el valor de la referencia
    (newVal) => {
      console.log(newVal.logo);
      if (newVal.logo) {
        // Lógica para cargar datos basados en el nuevo valor
        img_src.value = newVal.logo;
      }
    },
    { immediate: true } // Ejecuta el watch inmediatamente al montar el componente
  );
  </script>
  
  <style scoped>
    .custom-editor .ck-editor__editable {
      min-height: 300px; /* Ajusta a la altura deseada */
      max-height: 600px; /* Opcional: Define un límite máximo */
      overflow: auto; /* Para manejar contenido largo */
    }
  </style>